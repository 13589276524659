import { MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import commonTheme from "shared/theme/commonTheme";
import Home from "components/Home";
import AboutUs from "components/AboutUs";
import Program30Day from "components/Program30Day";
import GoApp from "components/GoApp";
import Contact from "components/Contact";
import EULA from "components/EULA";
import PrivacyPolicy from "components/PrivacyPolicy";
import TermsOfUse from "components/TermsOfUse";

import "./App.css";

function App() {
  return (
    <MuiThemeProvider theme={commonTheme}>
      {/* <Notification /> */}
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/aboutUs">
            <AboutUs />
          </Route>
          <Route exact path="/goApp">
            <GoApp />
          </Route>
          <Route exact path="/contactUs">
            <Contact />
          </Route>
          <Route exact path="/30DayProgram">
            <Program30Day />
          </Route>
          <Route exact path="/eula">
            <EULA />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/terms-of-use">
            <TermsOfUse />
          </Route>
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
