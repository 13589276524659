// General
import { makeStyles, createStyles, Theme, useTheme, Box, Button, Grid, Typography, TextField, Snackbar, Hidden, Divider } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useState, useEffect } from "react";
import Pp4mncDrawer from "shared/components/Pp4mncDrawer";
import Pp4mncAppBar from "shared/components/Pp4mncAppBar";
import Pp4mncFooter from "shared/components/Pp4mncFooter";
import { Pages } from "shared/utils";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import { getSocialLinks } from "shared/utils";

//Common

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: 40,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    loadingLogo: {
      width: 200,
      position: "absolute",
      top: window.innerHeight / 2 - 100 + "px",
      left: window.innerWidth / 2 - 100 + "px",
    },
    mainContentDiv: {
      overflow: "hidden",
    },
    colorBoxHeader: {
      padding: 20,
      paddingTop: 100,
      backgroundColor: theme.palette.baseColors.primary,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    thinText: {
      fontFamily: "Arial",
    },
  })
);

interface IProps {
  //   //logOut: () => void;
  //   authUser: any;
  //   productList: Product[];
  //   productTypeDetails: any[];
  //   setProductTypeDetails: (newPtd: any[]) => void;
  //   clearCart?: boolean;
}

const Contact: React.FC<IProps> = (props) => {
  //const { productList, productTypeDetails, setProductTypeDetails } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const [errorDetail, setErrorDetail] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [msgError, setMsgError] = useState(false);

  const [recaptchaVerified, setRecaptchaVerified] = useState(false);

  // const [selectedSocials, setSelectedSocials] = useState([]);

  // const ITEM_HEIGHT = 48;
  // const ITEM_PADDING_TOP = 8;
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //       width: 250,
  //     },
  //   },
  // };

  // const getStyles = (name, selectedSocials, theme) => {
  //   return {
  //     fontFamily: selectedSocials.indexOf(name) === -1 ? "Arial" : theme.typography.fontFamily,
  //   };
  // };

  // const handleChange = (event) => {
  //   setSelectedSocials(event.target.value);
  // };

  useEffect(() => {
    if (document.title !== "Contact ParaPer4mance") {
      document.title = "Contact ParaPer4mance";
      window.scrollTo(0, 0);
    }
  });

  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
    setNameError(false);
    setEmailError(false);
    setMsgError(false);
    setErrorDetail("");
    setOpenError(false);
    setOpenSuccess(false);
    setRecaptchaVerified(false);
  };

  const sendEmail = (event) => {
    event.preventDefault();

    !name ? setNameError(true) : setNameError(false);
    !email ? setEmailError(true) : setEmailError(false);
    !message ? setMsgError(true) : setMsgError(false);

    if (name && email && message) {
      emailjs.sendForm("service_hzffsns", "template_p8eg9jp", window.document.forms[0], "user_xGyVBItXjzNAApDSVp5RZ").then(
        (result) => {
          setErrorDetail("");
          setOpenSuccess(true);
          resetForm();
        },
        (error) => {
          setErrorDetail(error.text);
          setOpenError(true);
        }
      );
    } else {
      console.log("ERROR");
    }
  };

  // const handleRecaptchaChange = (value) => {
  //   console.log("R=" + value);
  // };

  return (
    <div className={classes.mainContentDiv}>
      <Pp4mncAppBar openState={open} setOpenState={setOpen} />
      <Pp4mncDrawer currentPage={Pages.CONTACT} openState={open} setOpenState={setOpen} />
      <Box display="flex" flexDirection="column" flex="1" flexGrow="1" className={classes.colorBoxHeader}>
        <Grid container>
          <Grid item sm={12}>
            <Hidden mdUp>
              <Typography variant="h2" style={{ color: theme.palette.baseColors.white }}>
                Contact Us
              </Typography>
              <Typography variant="body1" style={{ color: theme.palette.baseColors.black }}>
                Don't just contact us, join our community on social media. You may be the inspiration to someone else today!
              </Typography>
            </Hidden>
            <Hidden smDown>
              <Typography variant="h1" style={{ color: theme.palette.baseColors.white }}>
                Contact Us
              </Typography>
              <Typography variant="h5" style={{ color: theme.palette.baseColors.black }}>
                Don't just contact us, join our community on social media. You may be the inspiration to someone else today!
              </Typography>
            </Hidden>

            {getSocialLinks({ textAlign: "center", color: "#FFFFFF" })}
          </Grid>
        </Grid>
      </Box>
      <div style={{ padding: 20 }}>
        <form id="contactUsForm">
          <TextField
            label="Your Name"
            variant="outlined"
            color="primary"
            name="from_name"
            id="from_name"
            fullWidth
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
            error={nameError}
            helperText="Your Name (Required)"
          />

          <TextField
            label="Email Address"
            variant="outlined"
            color="primary"
            name="reply_to"
            id="reply_to"
            fullWidth
            style={{ marginTop: 20 }}
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            error={emailError}
            helperText="Your Email Address (Required)"
          />

          <TextField
            label="Message"
            variant="outlined"
            color="primary"
            name="message"
            id="message"
            fullWidth
            multiline
            rows={4}
            style={{ marginTop: 20 }}
            value={message}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            error={msgError}
            helperText="Your Message To ParaPer4mance (Required)"
          />

          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <ReCAPTCHA
            id="contact"
            sitekey="6LfBPTUbAAAAAKMJzj04EIfmdckS1h940ovs91Bj"
            onChange={(value) => {
              value && setRecaptchaVerified(true);
            }}
            onExpired={() => {
              setRecaptchaVerified(false);
            }}
            onErrored={() => {
              setRecaptchaVerified(false);
            }}
          />

          <Button fullWidth color="primary" type="submit" disabled={!recaptchaVerified} onClick={sendEmail} variant="contained" style={{ marginTop: 20 }}>
            Submit
          </Button>
        </form>
      </div>

      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSuccess(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenSuccess(false);
          }}
          severity="success"
        >
          Thanks! Your message was successfully sent!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={() => {
          setOpenError(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenError(false);
          }}
          severity="error"
        >
          Oops. It seems we've run into an error. Please notify us on social media if you can. Thank you!
          <br />
          Detail:{" "}
          <Typography variant="caption" className={classes.thinText}>
            {errorDetail}
          </Typography>
        </Alert>
      </Snackbar>

      <Pp4mncFooter />
    </div>
  );
};

export default Contact;
