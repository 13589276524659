// General
import { makeStyles, createStyles, Theme, Drawer, useTheme, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { useState } from "react";
import { Pages, openWidth } from "shared/utils";
import { NavPreviousIcon } from "../Icons";
import { Redirect } from "react-router-dom";
import { getSocialLinks } from "shared/utils";
//Common

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: openWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: openWidth,
      backgroundColor: "#333333",
      color: "#ffffff",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
      backgroundColor: "#333333",
    },
  })
);

interface IProps {
  currentPage: Pages;
  openState: boolean;
  setOpenState: (value: boolean) => void;
}

const Pp4mncDrawer: React.FC<IProps> = (props) => {
  const { currentPage, openState, setOpenState } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [redirectToHome, setRedirectToHome] = useState(false);
  const [redirectToAboutUs, setRedirectToAboutUs] = useState(false);
  const [redirectToGoApp, setRedirectToGoApp] = useState(false);
  const [redirectToContact, setRedirectToContact] = useState(false);
  const [redirectTo30DayProgram, setRedirectTo30DayProgram] = useState(false);

/*
<ListItem
            button
            onClick={() => {
              //window.open("https://shop.paraper4mance.com");
              window.location.href = "https://shop.paraper4mance.com";
            }}
            style={{ borderBottom: "1px solid #FFFFFF" }}
          >
            <ListItemIcon>
              <img src="/images/logos/icon.png" alt="ParaPer4mance Current Page" style={{ width: 20, height: 25, display: "none" }} />
            </ListItemIcon>
            <ListItemText primary="Merchandise" />
          </ListItem>
          
          <ListItem
            button
            onClick={() => {
              setOpenState(false);
              setRedirectTo30DayProgram(true);
            }}
            style={{ borderBottom: "1px solid #FFFFFF" }}
          >
            <ListItemIcon>
              <img
                src="/images/logos/icon.png"
                alt="ParaPer4mance Current Page"
                style={currentPage === Pages.PROGRAM30 ? { width: 20, height: 25 } : { width: 20, height: 25, display: "none" }}
              />
            </ListItemIcon>
            <ListItemText primary="FREE! 30 Day Program" style={{ color: theme.palette.baseColors.altSecondary }} />
          </ListItem>
*/

  const getMenuOptions = () => {
    return (
      <React.Fragment>
        <List>
          <ListItem
            button
            style={{ borderBottom: "1px solid #FFFFFF" }}
            onClick={() => {
              setOpenState(false);
              setRedirectToHome(true);
            }}
          >
            <ListItemIcon>
              <img
                src="/images/logos/icon.png"
                alt="ParaPer4mance Current Page"
                style={currentPage === Pages.HOME ? { width: 20, height: 25 } : { width: 20, height: 25, display: "none" }}
              />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          
          <ListItem
            button
            onClick={() => {
              setOpenState(false);
              setRedirectToGoApp(true);
            }}
            style={{ borderBottom: "1px solid #FFFFFF" }}
          >
            <ListItemIcon>
              <img
                src="/images/logos/icon.png"
                alt="ParaPer4mance Current Page"
                style={currentPage === Pages.GO_APP ? { width: 20, height: 25 } : { width: 20, height: 25, display: "none" }}
              />
            </ListItemIcon>
            <ListItemText primary="ParaPer4mance GO App" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setOpenState(false);
              setRedirectToAboutUs(true);
            }}
            style={{ borderBottom: "1px solid #FFFFFF" }}
          >
            <ListItemIcon>
              <img
                src="/images/logos/icon.png"
                alt="ParaPer4mance Current Page"
                style={currentPage === Pages.ABOUT ? { width: 20, height: 25 } : { width: 20, height: 25, display: "none" }}
              />
            </ListItemIcon>
            <ListItemText primary="About Us" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setOpenState(false);
              setRedirectToContact(true);
            }}
            style={{ borderBottom: "1px solid #FFFFFF" }}
          >
            <ListItemIcon>
              <img
                src="/images/logos/icon.png"
                alt="ParaPer4mance Current Page"
                style={currentPage === Pages.CONTACT ? { width: 20, height: 25 } : { width: 20, height: 25, display: "none" }}
              />
            </ListItemIcon>
            <ListItemText primary="Contact Us" />
          </ListItem>
          
        </List>
      </React.Fragment>
    );
  };

  return (
    <>
      {redirectToHome && <Redirect push to="/" />}
      {redirectToAboutUs && <Redirect push to="/aboutUs" />}
      {redirectToGoApp && <Redirect push to="/goApp" />}
      {redirectToContact && <Redirect push to="/contactUs" />}
      {redirectTo30DayProgram && <Redirect push to="/30DayProgram" />}

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openState}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <img src="/images/logos/ParaPer4mance.svg" style={{ width: 200, marginRight: 25 }} alt="ParaPer4mance Shop" />

          <IconButton
            onClick={() => {
              setOpenState(false);
            }}
          >
            <NavPreviousIcon style={{ color: "#FFFFFF" }} />
          </IconButton>
        </div>
        <div>{openState && getMenuOptions()}</div>
        {getSocialLinks({ position: "absolute", bottom: 5, textAlign: "center" })}
        {/* <Grid container style={{ position: "absolute", bottom: 5, textAlign: "center" }}>
          <Grid item xs={4}>
            <Tooltip arrow title="Follow Us On Facebook">
              <IconButton
                color="inherit"
                onClick={() => {
                  window.open(facebookURL);
                }}
              >
                <Facebook style={{ width: 30, height: 30 }} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <Tooltip arrow title="Follow Us On Instagram">
              <IconButton
                color="inherit"
                onClick={() => {
                  window.open(instagramURL);
                }}
              >
                <Instagram style={{ width: 30, height: 30 }} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <Tooltip arrow title="Subscribe To Our YouTube Channel">
              <IconButton
                color="inherit"
                onClick={() => {
                  window.open(youtubeURL);
                }}
              >
                <YouTube style={{ width: 30, height: 30 }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid> */}
      </Drawer>
    </>
  );
};

export default Pp4mncDrawer;
