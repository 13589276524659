// General
import { makeStyles, createStyles, Theme, useTheme, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
//Common

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: 40,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    loadingLogo: {
      width: 200,
      position: "absolute",
      top: window.innerHeight / 2 - 100 + "px",
      left: window.innerWidth / 2 - 100 + "px",
    },
    mainContentDiv: {
      display: "flex",
      flex: "1 1 auto",
      flexDirection: "column",
      height: `calc(100vh - 120px)`,
      marginTop: 30,
      width: "100%",
      paddingTop: 30,
      paddingBottom: 30,
      // marginLeft: "auto",
      // marginRight: "auto",
    },
    colorboxBlockAlt: {
      padding: 20,
      backgroundColor: theme.palette.baseColors.secondary,
    },
  })
);

interface IProps {
  //   //logOut: () => void;
  //   authUser: any;
  //   productList: Product[];
  //   productTypeDetails: any[];
  //   setProductTypeDetails: (newPtd: any[]) => void;
  //   clearCart?: boolean;
}

const TermsOfUse: React.FC<IProps> = (props) => {
  //const { productList, productTypeDetails, setProductTypeDetails } = props;
  const classes = useStyles();
  const theme = useTheme();
  //const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (document.title !== "ParaPer4mance Terms Of Use") {
      document.title = "ParaPer4mance Terms Of Use";
      window.scrollTo(0, 0);
    }
  });

  return (
    <div>
      <div className={classes.colorboxBlockAlt}>
        <img src="/images/logos/GoAppLogo.svg" alt="ParaPer4mance GO App" style={{ width: "60%" }} />
        <br />
        <Typography variant="h2" style={{ color: theme.palette.baseColors.white }}>
          Terms Of Use
        </Typography>
        <br />
        <Typography variant="body1" style={{ color: theme.palette.baseColors.white }}>
          Last Modified: [11/12/2020]
        </Typography>
      </div>
      <div style={{ padding: 20 }}>
        <div>
          <div>
            <div>
              <p>
                <strong>Acceptance of the Terms of Use</strong>
              </p>
              <p>
                These terms of use are entered into by and between You and ParaPer4mance, Inc. (“<strong>Company</strong>,” “<strong>we</strong>,” or “<strong>us</strong>“). The
                following terms and conditions, together with any documents they expressly incorporate by reference (collectively, “<strong>Terms of Use</strong>“), govern your
                access to and use of ParaPer4mance GO, including any content, functionality, and services offered on or through ParaPer4mance GO (the “<strong>App</strong>“),
                whether as a guest or a registered user.
              </p>
              <p>
                Please read the Terms of Use carefully before you start to use the App.{" "}
                <strong>
                  By using the App, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at https://paraper4mance.com/privacy-policy],
                  incorporated herein by reference.
                </strong>{" "}
                If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the App.
              </p>
              <p>
                This App is offered and available to users who are 13 years of age or older and reside in the United States or any of its territories or possessions. By using this
                App, you represent and warrant that you are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you
                do not meet all of these requirements, you must not access or use the App.
              </p>
              <p>
                <strong>Changes to the Terms of Use</strong>
              </p>
              <p>
                We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all
                access to and use of the App thereafter. However, any changes to the dispute resolution provisions set out in Governing Law and Jurisdiction will not apply to any
                disputes for which the parties have actual notice on or before the date the change is posted on the App.
              </p>
              <p>
                Your continued use of the App following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from
                time to time so you are aware of any changes, as they are binding on you.
              </p>
              <p>
                <strong>Medical Disclaimer</strong>
              </p>
              <p>
                By using this App, you understand that although the Company hopes you will derive benefit from the App, you should always consult a your doctor or healthcare
                provider prior to engaging in any exercise program or regimen, as well as any wellness or nutrition change. You further understand that the App is not meant to
                diagnose any health condition or suggest any course of treatment and does not promise any specific health or wellness result stemming from your use of the App. Any
                application of or engagement in any workout program or health or nutrition regimen found herein is undertaken solely by you at your own risk. This App is intended
                for educational and social purposes and you should consult your doctor or a healthcare provider if you have any questions or concerns about your health. By using
                this App, you agree that any action taken by you is done so at your own risk and is in line with any recommendations made to you by a medical professional.
              </p>
              <p>
                <strong>Accessing the App and Account Security</strong>
              </p>
              <p>
                We reserve the right to withdraw or amend this App, and any service or material we provide on the App, in our sole discretion without notice. We will not be liable
                if for any reason all or any part of the App is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the App, or
                the entire App, to users, including registered users.
              </p>
              <p>You are responsible for both:</p>
              <ul>
                <li>Making all arrangements necessary for you to have access to the App.</li>
                <li>Ensuring that all persons who access the App through your device or internet connection are aware of these Terms of Use and comply with them.</li>
              </ul>
              <p>
                To access the App or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use
                of the App that all the information you provide on the App is correct, current, and complete. You agree that all information you provide to register with this App
                or otherwise, including, but not limited to, through the use of any interactive features on the App, is governed by our <em>Privacy Policy</em>{" "}
                <a href="https://paraper4mance.com/privacy-policy/">https://paraper4mance.com/privacy-policy</a>, and you consent to all actions we take with respect to your
                information consistent with our Privacy Policy.
              </p>
              <p>
                If you choose, or are provided with, a user name, password, or any other piece of information as part of our security procedures, you must treat such information as
                confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any
                other person with access to this App or portions of it using your user name, password, or other security information. You agree to notify us immediately of any
                unauthorized access to or use of your user name or password or any other breach of security. You should use particular caution when accessing your account from a
                public or shared computer or device so that others are not able to view or record your password or other personal information.
              </p>
              <p>
                We have the right to disable any user name, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no
                reason, including if, in our opinion, you have violated any provision of these Terms of Use.
              </p>
              <p>
                <strong>Intellectual Property Rights</strong>
              </p>
              <p>
                The App and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and
                the design, selection, and arrangement thereof) are owned by the Company, its licensors, or other providers of such material and are protected by United States and
                international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
              </p>
              <p>
                These Terms of Use permit you to use the App for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of,
                publicly display, publicly perform, republish, download, store, or transmit any of the material on our App, except as follows:
              </p>
              <ul>
                <li>Your computer or device may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
                <li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>
                <li>
                  We provide desktop, mobile, or other applications for download. You may download a single copy to your computer or mobile device solely for your own personal,
                  non-commercial use, provided you agree to be bound by our End User License Agreement <a href="https://paraper4mance.com/eula/">https://paraper4mance.com/eula/</a>{" "}
                  for such applications.
                </li>
                <li>
                  If we provide <em>social media features(</em> <a href="https://getstream.io/activity-feed-design/">https://getstream.io/activity-feed-design/</a>,{" "}
                  <a href="https://www.twilio.com/">https://www.twilio.com/</a>) with certain content, you may take such actions as are enabled by such features.
                </li>
              </ul>
              <p>You must not:</p>
              <ul>
                <li>Modify copies of any materials from this App.</li>
                <li>Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.</li>
              </ul>
              <p>You must not access or use for any commercial purposes any part of the App or any services or materials available through the App.</p>
              <p>If you wish to make any use of material on the App other than that set out in this section, please address your request to: info@paraper4mance.com.</p>
              <p>
                If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the App in breach of the Terms of Use, your right to
                use the App will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to
                the App or any content on the App is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the App not expressly
                permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.
              </p>
              <p>
                <strong>Parapoints</strong>
              </p>
              <p>
                Within the App, you will be offered the option to participate in a points-based reward system (“<strong>Parapoints</strong>”). Parapoints is a program sponsored by
                ParaPer4mance, Inc. which allows users to earn incentive points by using the App in certain ways. These points may provide some in-App benefits, such as access to
                certain content which was previously inaccessible. These points are only redeemable on the App. You understand that any Parapoints that you may earn do not
                represent real property to you. Parapoints have no fixed value, are not redeemable for cash or any cash-equivalent, and are not transferrable to any other
                individual or entity. ParaPer4mance, Inc. reserves the right to suspend or alter the Parapoints system without prior notice and at our sole discretion.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>Trademarks</strong>
              </p>
              <p>The Company name, the terms</p>
              <ul>
                <ul>
                  <li>
                    ParaPoints (<em>Digital Currency</em>)
                  </li>
                  <li>
                    <em>Making Fitness Adaptive</em>
                  </li>
                  <li>
                    <em>Adaptive Fitness</em>
                  </li>
                  <li>
                    <em>ParaPer4mance</em>
                  </li>
                  <li>
                    <em>ParaPer4mance GO</em>
                  </li>
                  <li>
                    <em>ParaPe4mance Health</em>
                  </li>
                  <li>
                    The Domain,<em>ParaPer4mance.com</em>
                  </li>
                  <li>
                    The Domain,<em>ParaPer4manceHealth.com</em>
                  </li>
                  <li>
                    The Domain,<em>Nac.com</em>( <em>National Adaptive Certification)</em>
                  </li>
                  <li>
                    Mobile App, <em>ParaPer4mance GO ( IOS &amp; Android )</em>
                  </li>
                </ul>
              </ul>
              <p>
                the Company logo, and all related names, logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You
                must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs, and slogans on this App are
                the trademarks of their respective owners.
              </p>
              <p>
                <strong>Prohibited Uses</strong>
              </p>
              <p>You may use the App only for lawful purposes and in accordance with these Terms of Use. You agree not to use the App:</p>
              <ul>
                <li>
                  In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of
                  data or software to and from the US or other countries).
                </li>
                <li>
                  For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally
                  identifiable information, or otherwise.
                </li>
                <li>To send, knowingly receive, upload, download, use, or re-use any material that does not comply with the Content Standards set out in these Terms of Use.</li>
                <li>
                  To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any “junk mail,” “chain letter,”
                  “spam,” or any other similar solicitation.
                </li>
                <li>
                  To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using
                  email addresses or screen names associated with any of the foregoing).
                </li>
                <li>
                  To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the App, or which, as determined by us, may harm the Company or users of
                  the App, or expose them to liability.
                </li>
              </ul>
              <p>Additionally, you agree not to:</p>
              <ul>
                <li>
                  Use the App in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the App, including their ability
                  to engage in real time activities through the App.
                </li>
                <li>
                  Use any robot, spider, or other automatic device, process, or means to access the App for any purpose, including monitoring or copying any of the material on the
                  App.
                </li>
                <li>
                  Use any manual process to monitor or copy any of the material on the App, or for any other purpose not expressly authorized in these Terms of Use, without our
                  prior written consent.
                </li>
                <li>Use any device, software, or routine that interferes with the proper working of the App.</li>
                <li>Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
                <li>
                  Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the App, the server on which the App is stored, or any server, computer,
                  or database connected to the App.
                </li>
                <li>Attack the App via a denial-of-service attack or a distributed denial-of-service attack.</li>
                <li>Otherwise attempt to interfere with the proper working of the App.</li>
              </ul>
              <p>
                <strong>User Contributions</strong>
              </p>
              <p>
                The App may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards and other interactive features (collectively, “
                <strong>Interactive Services</strong>“) that allow users to post, submit, publish, display, or transmit to other users or other persons (hereinafter, “
                <strong>post</strong>“) content or materials (collectively, “<strong>User Contributions</strong>“) on or through the App.
              </p>
              <p>All User Contributions must comply with the Content Standards set out in these Terms of Use.</p>
              <p>
                Any User Contribution you post to the site will be considered non-confidential and non-proprietary. By providing any User Contribution on the App, you grant us and
                our affiliates and service providers, and each of their and our respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display,
                distribute, and otherwise disclose to third parties any such material according to your account settings.
              </p>
              <p>You represent and warrant that:</p>
              <ul>
                <li>
                  You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service
                  providers, and each of their and our respective licensees, successors, and assigns.
                </li>
                <li>All of your User Contributions do and will comply with these Terms of Use.</li>
              </ul>
              <p>
                You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not the Company, have full responsibility for
                such content, including its legality, reliability, accuracy, and appropriateness.
              </p>
              <p>We are not responsible or liable to any third party for the content or accuracy of any User Contributions posted by you or any other user of the App.</p>
              <p>
                <strong>Monitoring and Enforcement; Termination</strong>
              </p>
              <p>We have the right to:</p>
              <ul>
                <li>Remove or refuse to post any User Contributions for any or no reason in our sole discretion.</li>
                <li>
                  Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User
                  Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity,
                  threatens the personal safety of users of the App or the public, or could create liability for the Company.
                </li>
                <li>
                  Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their
                  intellectual property rights or their right to privacy.
                </li>
                <li>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the App.</li>
                <li>Terminate or suspend your access to all or part of the App for any or no reason, including without limitation, any violation of these Terms of Use.</li>
              </ul>
              <p>
                Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the
                identity or other information of anyone posting any materials on or through the App. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND
                SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER
                SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
              </p>
              <p>
                However, we do not undertake to review all material before it is posted on the App, and cannot ensure prompt removal of objectionable material after it has been
                posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We
                have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.
              </p>
              <p>
                <strong>Content Standards</strong>
              </p>
              <p>
                These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all
                applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, User Contributions must not:
              </p>
              <ul>
                <li>Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.</li>
                <li>
                  Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.
                </li>
                <li>Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.</li>
                <li>
                  Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability
                  under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policy:
                  https://paraper4mance.com/privacy-policy/
                </li>
                <li>Be likely to deceive any person.</li>
                <li>Promote any illegal activity, or advocate, promote, or assist any unlawful act.</li>
                <li>Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person.</li>
                <li>Impersonate any person, or misrepresent your identity or affiliation with any person or organization.</li>
                <li>Involve commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter, or advertising.</li>
                <li>Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.</li>
              </ul>
              <p>
                <strong>Copyright Infringement</strong>
              </p>
              <p>
                If you believe that any User Contributions violate your copyright, please send us a notice of copyright infringement at{" "}
                <a href="mailto:info@paraper4mance.com">info@paraper4mance.com</a>. It is the policy of the Company to terminate the user accounts of repeat infringers.
              </p>
              <p>
                <strong>Reliance on Information Posted</strong>
              </p>
              <p>
                The information presented on or through the App is made available solely for general information purposes. We do not warrant the accuracy, completeness, or
                usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from
                any reliance placed on such materials by you or any other visitor to the App, or by anyone who may be informed of any of its contents.
              </p>
              <p>
                This App may include content provided by third parties, including materials provided by other users, bloggers, and third-party licensors, syndicators, aggregators,
                and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than
                the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not
                necessarily reflect the opinion of the Company. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided
                by any third parties.
              </p>
              <p>
                <strong>Changes to the App</strong>
              </p>
              <p>
                We may update the content on this App from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the App may be out of
                date at any given time, and we are under no obligation to update such material.
              </p>
              <p>
                <strong>Information About You and Your Visits to the App</strong>
              </p>
              <p>
                All information we collect on this App is subject to our Privacy Policy, https://paraper4mance.com/privacy-policy/. By using the App, you consent to all actions
                taken by us with respect to your information in compliance with the Privacy Policy.
              </p>
              <p>
                Additional terms and conditions may also apply to specific portions, services, or features of the Website. All such additional terms and conditions are hereby
                incorporated by this reference into these Terms of Use.
              </p>
              <p>
                <strong>Linking to the App and Social Media Features</strong>
              </p>
              <p>
                You may link to our App, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a
                link in such a way as to suggest any form of association, approval, or endorsement on our part without our express written consent.
              </p>
              <p>This App may provide certain social media features that enable you to:</p>
              <ul>
                <li>Link from your own or certain third-party websites to certain content on this App.</li>
                <li>Send emails or other communications with certain content, or links to certain content, on this App.</li>
                <li>Cause limited portions of content on this App to be displayed or appear to be displayed on your own or certain third-party websites, social media, or apps.</li>
              </ul>
              <p>
                You may use these features solely as they are provided by us, solely with respect to the content they are displayed with, and otherwise in accordance with any
                additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:
              </p>
              <ul>
                <li>Cause the App or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking.</li>
                <li>Otherwise take any action with respect to the materials on this App that is inconsistent with any other provision of these Terms of Use.</li>
              </ul>
              <p>
                The website from which you are linking, or on which you make certain content accessible, must comply in all respects with the Content Standards set out in these
                Terms of Use.
              </p>
              <p>
                You agree to cooperate with us in causing any unauthorized framing or linking immediately to stop. We reserve the right to withdraw linking permission without
                notice.
              </p>
              <p>We may disable all or any social media features and any links at any time without notice in our discretion.</p>
              <p>
                <strong>Links from the App</strong>
              </p>
              <p>
                If the App contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained
                in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no
                responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to this App,
                you do so entirely at your own risk and subject to the terms and conditions of use for such websites.
              </p>
              <p>
                <strong>Geographic Restrictions</strong>
              </p>
              <p>
                The owner of the App is based in the State of Pennsylvania in the United States. We provide this App for use only by persons located in the United States. We make
                no claims that the App or any of its content is accessible or appropriate outside of the United States. Access to the App may not be legal by certain persons or in
                certain countries. If you access the App from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.
              </p>
              <p>
                <strong>Disclaimer of Warranties</strong>
              </p>
              <p>
                You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the App will be free of viruses or other
                destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and
                accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW,
                WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT
                YOUR MOBILE DEVICE, COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE APP OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                THE APP OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
              </p>
              <p>
                YOUR USE OF THE APP, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE APP IS AT YOUR OWN RISK. THE APP, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED
                THROUGH THE APP ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY
                PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY
                OF THE APP. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE APP, ITS CONTENT, OR ANY
                SERVICES OR ITEMS OBTAINED THROUGH THE APP WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR APP OR THE SERVER THAT
                MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE APP OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE APP WILL OTHERWISE MEET YOUR NEEDS
                OR EXPECTATIONS.
              </p>
              <p>
                TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT
                NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
              </p>
              <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
              <p>
                <strong>Limitation on Liability</strong>
              </p>
              <p>
                TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR
                DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE APP, ANY APPS LINKED TO
                IT, ANY CONTENT ON THE APP OR SUCH OTHER APPS, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED
                TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
                GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
              </p>
              <p>THE LIMITATION OF LIABILITY SET OUT ABOVE DOES NOT APPLY TO LIABILITY RESULTING FROM OUR GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.</p>
              <p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
              <p>
                <strong>Governing Law and Jurisdiction</strong>
              </p>
              <p>
                All matters relating to the App and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual
                disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Delaware without giving effect to any choice or
                conflict of law provision or rule (whether of the State of Delaware or any other jurisdiction).
              </p>
              <p>
                Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the App shall be instituted exclusively in the federal courts of the
                United States or the courts of the State of Delaware, although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms of
                Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to
                venue in such courts.
              </p>
              <p>
                <strong>Limitation on Time to File Claims</strong>
              </p>
              <p>
                ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE APP MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF
                ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
              </p>
              <p>
                <strong>Waiver and Severability</strong>
              </p>
              <p>
                No waiver by the Company of any term or condition set out in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver
                of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right
                or provision.
              </p>
              <p>
                If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such
                provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.
              </p>
              <p>
                <strong>Entire Agreement</strong>
              </p>
              <p>
                The Terms of Use, our Privacy Policy, and End User License Agreement constitute the sole and entire agreement between you and ParaPer4mance, Inc. regarding the App
                and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the App.
              </p>
              <p>
                <strong>Your Comments and Concerns</strong>
              </p>
              <p>This App is operated by ParaPer4mance, Inc. info&#64;paraper4mance&#46;com</p>
              <p>All notices of copyright infringement claims should be sent to info&#64;paraper4mance&#46;com</p>
              <p>
                All other feedback, comments, requests for technical support, and other communications relating to the App should be directed to: info&#64;paraper4mance&#46;com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
