import axios from "axios";
import { saveAs } from "file-saver";

export const downloadFileFromUrl = async (url: string, filename?: string) => {
  if (!filename) {
    if (url !== undefined) {
      const splitUrl = url.split("/");
      const lastItem = splitUrl.pop();
      if (lastItem) {
        filename = lastItem.split("?")[0];
      }
    }
  }

  try {
    const blob = await axios({
      url,
      method: "get",
      responseType: "blob",
    }).then((r) => r.data);

    if (blob) {
      saveAs(blob, filename);
    } else {
      console.error("Error saving file. Please try again.");
      // notify.error({
      //   text: "Error saving file. Please try again.",
      //   toastId: "download-file-error",
      // });
    }
  } catch (error) {
    console.error("Error downloading file from URL", error);
    // notify.error({
    //   text: "Error downloading file. Please try again.",
    //   toastId: "download-file-error",
    // });
  }
};

// export const downloadFileFromBucket = async (fileName) => {
//   let success = true;
//   try {
//     const params = { fileName: fileName };
//     const result = await Parse.Cloud.run("generateSignedDownloadURL", params);
//     if (result.url) {
//       await downloadFileFromUrl(result.url, fileName);
//     } else {
//       success = false;
//       notify.error({
//         text: "Error saving file. Please try again.",
//         toastId: "download-file-error",
//       });
//     }
//   } catch (error) {
//     success = false;
//     console.error("Error downloading file from URL", error);
//     notify.error({
//       text: "Error downloading file. Please try again. ",
//       toastId: "download-file-error",
//     });
//   }

//   return new Promise((resolve) => {
//     resolve(success);
//   });
// };

export const downloadCsv = ({ filename, rows }) => {
  const csvString = rows.map((row) => row.map((c) => (/[^0-9.-]/.test(c) ? `${c}` : c)).join(",")).join("\r\n");

  saveAs(new window.Blob([csvString], { type: "text/csv" }), filename);
};
