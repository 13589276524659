// General
import { makeStyles, createStyles, Theme, useTheme, Paper, Typography, Hidden, Box, Grid } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem/TimelineItem";
//import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator/TimelineSeparator";
import React, { useState, useEffect } from "react";
import { DriveEta } from "@material-ui/icons";
import { TrophyVariant, Bandage } from "mdi-material-ui";
import Pp4mncDrawer from "shared/components/Pp4mncDrawer";
import Pp4mncAppBar from "shared/components/Pp4mncAppBar";
import Pp4mncFooter from "shared/components/Pp4mncFooter";
import { Pages } from "shared/utils";
//Common

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: "6px 16px",
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
    appBar: {
      top: 40,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    loadingLogo: {
      width: 200,
      position: "absolute",
      top: window.innerHeight / 2 - 100 + "px",
      left: window.innerWidth / 2 - 100 + "px",
    },
    mainContentDiv: {
      overflow: "hidden",
    },
    colorBoxHeader: {
      padding: 20,
      paddingTop: 100,
      backgroundColor: theme.palette.baseColors.primary,
    },
    colorboxBg: {
      marginTop: 50,
      marginLeft: "auto",
      marginRight: "auto",
      //backgroundColor: theme.palette.baseColors.primaryLighter,
      backgroundColor: theme.palette.baseColors.primary,
      overflow: "visible",
      width: "80%",
      borderRadius: 10,
      padding: 20,
    },
  })
);

interface IProps {
  //   //logOut: () => void;
  //   authUser: any;
  //   productList: Product[];
  //   productTypeDetails: any[];
  //   setProductTypeDetails: (newPtd: any[]) => void;
  //   clearCart?: boolean;
}

const AboutUs: React.FC<IProps> = (props) => {
  //const { productList, productTypeDetails, setProductTypeDetails } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (document.title !== "About ParaPer4mance") {
      document.title = "About ParaPer4mance";
      window.scrollTo(0, 0);
    }
  });

  return (
    <div className={classes.mainContentDiv}>
      <Pp4mncAppBar openState={open} setOpenState={setOpen} />
      <Pp4mncDrawer currentPage={Pages.ABOUT} openState={open} setOpenState={setOpen} />
      <Box display="flex" flexDirection="column" flex="1" flexGrow="1" className={classes.colorBoxHeader}>
        <Grid container>
          <Grid item sm={12}>
            <Hidden lgUp>
              <Typography variant="h2" style={{ color: theme.palette.baseColors.white }}>
                About Us
              </Typography>
            </Hidden>
            <Hidden mdDown>
              <Typography variant="h1" style={{ color: theme.palette.baseColors.white }}>
                About Us
              </Typography>
            </Hidden>
            <br />
            <Hidden lgUp>
              <Typography variant="h3" style={{ color: theme.palette.baseColors.black }}>
                Mission
              </Typography>
              <Typography variant="body1" style={{ color: theme.palette.baseColors.white }}>
                Guide, Connect and Inspire
              </Typography>
            </Hidden>
            <Hidden mdDown>
              <Typography variant="h1" style={{ color: theme.palette.baseColors.black }}>
                Mission
              </Typography>
              <Typography variant="h5" style={{ color: theme.palette.baseColors.white }}>
                Guide, Connect and Inspire
              </Typography>
            </Hidden>
            <br />

            <Hidden lgUp>
              <Typography variant="h3" style={{ color: theme.palette.baseColors.black }}>
                Vision
              </Typography>

              <Typography variant="body1" style={{ color: theme.palette.baseColors.white }}>
                To empower individuals, communities, and organizations around the world to independence.
              </Typography>
            </Hidden>
            <Hidden mdDown>
              <Typography variant="h1" style={{ color: theme.palette.baseColors.black }}>
                Vision
              </Typography>

              <Typography variant="h5" style={{ color: theme.palette.baseColors.white }}>
                To empower individuals, communities, and organizations around the world to independence.
              </Typography>
            </Hidden>
          </Grid>
        </Grid>
      </Box>

      <div style={{ padding: 20, textAlign: "center" }}>
        <Typography variant="h2" color="primary">
          Founder/CEO
        </Typography>
        <Typography variant="h3" color="secondary">
          Kenneth Keitt
        </Typography>
        <div className={classes.colorboxBg}>
          <img src="/images/pp4mnc/kenneth-collage.jpg" style={{ width: "80%" }} alt="Kenneth Keitt Collage" />
        </div>
      </div>

      <Timeline align="left" style={{ position: "relative", left: "-85%", width: "180%" }}>
        <TimelineItem>
          {/* <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              mm-dd-yyyy <ArrowRight style={{ position: "relative", top: 7 }} />
            </Typography>
          </TimelineOppositeContent> */}
          <TimelineSeparator>
            <TimelineDot color="secondary" variant="outlined" style={{ overflow: "hidden", padding: 0 }}>
              <img src="/images/logos/marinesLogo.jpg" alt="United States Marine Corps Logo" style={{ width: 32, height: 32 }} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1">
                [08-2007] Marines
              </Typography>
              <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  style={{
                    minHeight: 200,
                    //borderRadius: 4,
                    backgroundImage: `url("/images/logos/USMC.png")`,
                    backgroundPosition: "center left",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  {/* <img src="/images/logos/USMC.png" style={{ width: 32, height: 32 }} /> */}
                  {/* <img src="/images/pp4mnc/carAccident.jpg" style={{ width: "100%", height: "auto", borderRadius: 4, border: "1px solid #CCCCCC" }} /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography>Graduated from high school and enlisted in the Marines. Began boot camp at Marine Corps recruit depot at Parris Island</Typography>
                </Grid>
              </Grid>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          {/* <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              <ArrowLeft style={{ position: "relative", top: 7 }} /> mm-dd-yyyy
            </Typography>
          </TimelineOppositeContent> */}
          <TimelineSeparator>
            <TimelineDot color="secondary" variant="outlined" style={{ overflow: "hidden", padding: 0 }}>
              {/* <Ammunition /> */}
              <img src="/images/logos/marinesLogo.jpg" alt="United States Marine Corps Logo" style={{ width: 32, height: 32 }} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1">
                [09-2009] Tour In Iraq (OIF)
              </Typography>
              <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  style={{
                    minHeight: 200,
                    //borderRadius: 4,
                    backgroundImage: `url("/images/logos/oifBadge.png")`,
                    backgroundPosition: "center left",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  {/* <img src="/images/pp4mnc/carAccident.jpg" style={{ width: "100%", height: "auto", borderRadius: 4, border: "1px solid #CCCCCC" }} /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography>Began a seven month combat tour in Iraq in support of Operation Iraqi Freedom</Typography>
                </Grid>
              </Grid>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          {/* <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              Dec 2012 <ArrowRight style={{ position: "relative", top: 7 }} />
            </Typography>
          </TimelineOppositeContent> */}
          <TimelineSeparator>
            <TimelineDot color="primary" variant="outlined">
              <DriveEta />
            </TimelineDot>
            <TimelineConnector className={classes.secondaryTail} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1">
                [12-2012] Car Accident and Spinal Cord Injury
              </Typography>
              <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  style={{
                    minHeight: 200,
                    borderRadius: 4,
                    backgroundImage: `url("/images/pp4mnc/carAccident.jpg")`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  {/* <img src="/images/pp4mnc/carAccident.jpg" style={{ width: "100%", height: "auto", borderRadius: 4, border: "1px solid #CCCCCC" }} /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography>
                    In December of 2012, my life changed when I was ejected from a vehicle due to not wearing a seatbelt. In the hospital, I found that I broke my back in three
                    spots – T7, T8, and T9. Learning that I may never walk again was a terrifying and devastating moment.
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          {/* <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              <ArrowLeft style={{ position: "relative", top: 7 }} /> Apr 2013
            </Typography>
          </TimelineOppositeContent> */}
          <TimelineSeparator>
            <TimelineDot color="primary" variant="outlined">
              <Bandage />
            </TimelineDot>
            <TimelineConnector className={classes.secondaryTail} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1">
                [02-2013] Recovery
              </Typography>
              <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  style={{
                    minHeight: 200,
                    borderRadius: 4,
                    backgroundImage: `url("/images/pp4mnc/Ken.jpg")`,
                    backgroundPosition: "center top",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  {/* <img src="/images/pp4mnc/carAccident.jpg" style={{ width: "100%", height: "auto", borderRadius: 4, border: "1px solid #CCCCCC" }} /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography>
                    I spent four intense months in the hospital and then at <a href="https://mageerehab.jeffersonhealth.org">Magee</a> rehab in Philadelphia learning to adjust to
                    my new life without the use of my legs. Amidst sadness, depression, failure, loneliness, and more, something clicked…. I got sick and tired of being sick and
                    tired! I wanted to take back control over my life.
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          {/* <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              <ArrowLeft style={{ position: "relative", top: 7 }} /> mm-dd-yyyy
            </Typography>
          </TimelineOppositeContent> */}
          <TimelineSeparator>
            <TimelineDot color="primary" variant="outlined">
              <img src="/images/logos/icon2.png" alt="ParaPer4mance Logo" style={{ width: 20, marginLeft: 3, marginRight: 3, height: 24 }} />
            </TimelineDot>
            <TimelineConnector className={classes.secondaryTail} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1">
                [03-2018] ParaPer4mance
              </Typography>
              <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  style={{
                    minHeight: 200,
                    borderRadius: 4,
                    //backgroundImage: `url("/images/pp4mnc/KenInRing.jpg")`,
                    backgroundImage: `url("/images/pp4mnc/group1.jpg")`,
                    backgroundPosition: "center top",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    maxWidth: "100%",
                  }}
                >
                  {/* <img src="/images/pp4mnc/carAccident.jpg" style={{ width: "100%", height: "auto", borderRadius: 4, border: "1px solid #CCCCCC" }} /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography>
                    ParaPer4mance was created for me and you: Anyone who is in a wheelchair or who has limitations with mobility. Recently injured, elderly, paraplegic,
                    quadriplegic, wheelchair bound, recent stroke, spina bifida, and other complications that lead to the use of a wheelchair are all encouraged to use
                    ParaPer4mance’s mobile fitness routines.
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          {/* <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              mm-dd-yyyy <ArrowRight style={{ position: "relative", top: 7 }} />
            </Typography>
          </TimelineOppositeContent> */}
          <TimelineSeparator>
            <TimelineDot color="primary" variant="outlined">
              <TrophyVariant />
            </TimelineDot>
            <TimelineConnector className={classes.secondaryTail} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1">
                [05-2019] College Graduate
              </Typography>
              <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  style={{
                    minHeight: 200,
                    borderRadius: 4,
                    backgroundImage: `url("/images/pp4mnc/KenGrad.jpg")`,
                    backgroundPosition: "center top",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    maxWidth: "100%",
                  }}
                >
                  {/* <img src="/images/pp4mnc/carAccident.jpg" style={{ width: "100%", height: "auto", borderRadius: 4, border: "1px solid #CCCCCC" }} /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography>
                    Graduated from <a href="https://lehighvalley.psu.edu">Penn State Lehigh Valley</a> with a Bachelor Of Science in Business Administration (BSBA)
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary" variant="outlined">
              <img src="/images/logos/icon2.png" alt="ParaPer4mance Logo" style={{ width: 20, marginLeft: 3, marginRight: 3, height: 24 }} />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1">
                Today
              </Typography>
              <Typography>Guide - Connect - Inspire</Typography>
              <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  style={{
                    minHeight: 200,
                    borderRadius: 4,
                    backgroundImage: `url("/images/banners/banner1.jpg")`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    maxWidth: "100%",
                  }}
                >
                  {/* <img src="/images/logos/ParaPer4mance2.png" style={{ width: "100%", maxHeight: 120 }} /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography>
                    Every day is a new opportunity to take control of what allows you to gain strength, independence, and impact others. Maybe you don’t need ParaPer4mance today,
                    but ParaPer4mance needs you.
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      </Timeline>

      <Pp4mncFooter />
    </div>
  );
};

export default AboutUs;
