// General
import { makeStyles, createStyles, Theme, useTheme, Box, Grid, Typography, Divider, Hidden, List, ListItem, ListItemIcon, ListItemText,Snackbar } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Pp4mncDrawer from "shared/components/Pp4mncDrawer";
import Pp4mncAppBar from "shared/components/Pp4mncAppBar";
import Pp4mncFooter from "shared/components/Pp4mncFooter";
import { Pages, getAppStoreDownloadBtns } from "shared/utils";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
//Common

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: 40,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    loadingLogo: {
      width: 200,
      position: "absolute",
      top: window.innerHeight / 2 - 100 + "px",
      left: window.innerWidth / 2 - 100 + "px",
    },
    mainContentDiv: {
      overflow: "hidden",
    },
    colorBoxHeader: {
      padding: 20,
      paddingTop: 100,
      backgroundColor: theme.palette.baseColors.primary,
    },
    colorboxBlockAlt: {
      padding: 20,
      backgroundColor: theme.palette.baseColors.secondary,
    },
    thinText: {
      fontFamily: "Arial",
    },
  })
);

interface IProps {
  //   //logOut: () => void;
  //   authUser: any;
  //   productList: Product[];
  //   productTypeDetails: any[];
  //   setProductTypeDetails: (newPtd: any[]) => void;
  //   clearCart?: boolean;
}

const GoApp: React.FC<IProps> = (props) => {
  //const { productList, productTypeDetails, setProductTypeDetails } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openAppAlert,setOpenAppAlert] = useState(false);

  useEffect(() => {
    if (document.title !== "ParaPer4mance GO App") {
      document.title = "ParaPer4mance GO App";
      window.scrollTo(0, 0);
    }
  });

  const whyText = (
    <span className={classes.thinText}>
      ParaPer4mance wanted to develop a means of bringing people with limited mobility together by helping them interact inside and outside the gym, sharing their stories and
      goals, so they could develop a real sense of community and overcome their challenges through fitness and motivation. The main goal was to provide a safe, fun and active
      environment for the community to learn, enjoy and interact with each other.
    </span>
  );

  const whatText = (
    <span className={classes.thinText}>
      We created an app that combined all the amazing content ParaPer4mance has to offer, including their exercise videos, with a social network platform. The goal was to generate
      engagement and provide a safe and fun environment for users to communicate. Users can take advantage of instructional videos, chat and feed features, comment on and like
      updates and, most importantly, share their love for health and fitness.
    </span>
  );

  const list1 = (
    <List>
      <ListItem style={{ borderBottom: "1px solid #CCCCCC" }}>
        <ListItemIcon>
          <img src="/images/logos/icon2.png" alt="ParaPer4mance GO App" style={{ width: 20, height: 25 }} />
        </ListItemIcon>
        <ListItemText primary="Being able to connect with others that have limited mobility can help you get motivated, get stronger, get results!" />
      </ListItem>
      <ListItem style={{ borderBottom: "1px solid #CCCCCC" }}>
        <ListItemIcon>
          <img src="/images/logos/icon2.png" alt="ParaPer4mance GO App" style={{ width: 20, height: 25 }} />
        </ListItemIcon>
        <ListItemText primary="Share stories. Share workout plans. Share struggles. Share Triumphs." />
      </ListItem>
      <ListItem style={{ borderBottom: "1px solid #CCCCCC" }}>
        <ListItemIcon>
          <img src="/images/logos/icon2.png" alt="ParaPer4mance GO App" style={{ width: 20, height: 25 }} />
        </ListItemIcon>
        <ListItemText primary="Join a safe, fun and active community where you can learn, enjoy and interact with each other." />
      </ListItem>
    </List>
  );

  const list2 = (
    <List style={{ color: theme.palette.baseColors.white }}>
      <ListItem style={{ borderBottom: "1px solid #FFFFFF" }}>
        <ListItemIcon>
          <img src="/images/logos/icon.png" alt="ParaPer4mance GO App" style={{ width: 20, height: 25 }} />
        </ListItemIcon>
        <ListItemText primary="Watch and learn from any of our instructional videos developed for you by professionals." />
      </ListItem>
      <ListItem style={{ borderBottom: "1px solid #FFFFFF" }}>
        <ListItemIcon>
          <img src="/images/logos/icon.png" alt="ParaPer4mance GO App" style={{ width: 20, height: 25 }} />
        </ListItemIcon>
        <ListItemText primary="Chat with others about their fitness and nutrition routines to learn what works and what doesn't." />
      </ListItem>
      <ListItem style={{ borderBottom: "1px solid #FFFFFF" }}>
        <ListItemIcon>
          <img src="/images/logos/icon.png" alt="ParaPer4mance GO App" style={{ width: 20, height: 25 }} />
        </ListItemIcon>
        <ListItemText primary="Connect with others, create friendships and share your love of life, health and fitness." />
      </ListItem>
    </List>
  );

  const list3 = (
    <List>
      <ListItem style={{ borderBottom: "1px solid #CCCCCC" }}>
        <ListItemIcon>
          <img src="/images/logos/icon2.png" alt="ParaPer4mance GO App" style={{ width: 20, height: 25 }} />
        </ListItemIcon>
        <ListItemText primary="Chat" />
      </ListItem>
      <ListItem style={{ borderBottom: "1px solid #CCCCCC" }}>
        <ListItemIcon>
          <img src="/images/logos/icon2.png" alt="ParaPer4mance GO App" style={{ width: 20, height: 25 }} />
        </ListItemIcon>
        <ListItemText primary="Videos" />
      </ListItem>
      <ListItem style={{ borderBottom: "1px solid #CCCCCC" }}>
        <ListItemIcon>
          <img src="/images/logos/icon2.png" alt="ParaPer4mance GO App" style={{ width: 20, height: 25 }} />
        </ListItemIcon>
        <ListItemText primary="Instructional Videos" />
      </ListItem>

      <ListItem style={{ borderBottom: "1px solid #CCCCCC" }}>
        <ListItemIcon>
          <img src="/images/logos/icon2.png" alt="ParaPer4mance GO App" style={{ width: 20, height: 25 }} />
        </ListItemIcon>
        <ListItemText primary="Public Feed" />
      </ListItem>
      <ListItem style={{ borderBottom: "1px solid #CCCCCC" }}>
        <ListItemIcon>
          <img src="/images/logos/icon2.png" alt="ParaPer4mance GO App" style={{ width: 20, height: 25 }} />
        </ListItemIcon>
        <ListItemText primary="Social Community" />
      </ListItem>
      <ListItem style={{ borderBottom: "1px solid #CCCCCC" }}>
        <ListItemIcon>
          <img src="/images/logos/icon2.png" alt="ParaPer4mance GO App" style={{ width: 20, height: 25 }} />
        </ListItemIcon>
        <ListItemText primary="Comment on and Like Content" />
      </ListItem>
      <ListItem style={{ borderBottom: "1px solid #CCCCCC" }}>
        <ListItemIcon>
          <img src="/images/logos/icon2.png" alt="ParaPer4mance GO App" style={{ width: 20, height: 25 }} />
        </ListItemIcon>
        <ListItemText primary="Earn points from programs to purchase new content" />
      </ListItem>
      <ListItem style={{ borderBottom: "1px solid #CCCCCC" }}>
        <ListItemIcon>
          <img src="/images/logos/icon2.png" alt="ParaPer4mance GO App" style={{ width: 20, height: 25 }} />
        </ListItemIcon>
        <ListItemText primary="Private and Public Profiles" />
      </ListItem>
    </List>
  );

  return (
    <div className={classes.mainContentDiv}>
      <Snackbar open={openAppAlert} autoHideDuration={6000} onClose={()=>{setOpenAppAlert(false)}} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
  <Alert onClose={()=>{setOpenAppAlert(false)}} severity="success">
    We are currently reworking the app to bring you a better user experience. Follow us on social media for updates!
  </Alert>
</Snackbar>
      <Pp4mncAppBar openState={open} setOpenState={setOpen} />
      <Pp4mncDrawer currentPage={Pages.GO_APP} openState={open} setOpenState={setOpen} />
      <Box display="flex" flexDirection="column" flex="1" flexGrow="1" className={classes.colorBoxHeader}>
        <Grid container>
          <Grid item sm={12}>
            <Hidden lgUp>
              <Typography variant="h3" style={{ color: theme.palette.baseColors.white }}>
                ParaPer4mance GO App
              </Typography>
              {/* <br />
              <Typography variant="body1" style={{ color: theme.palette.baseColors.white }}>
                ParaPer4mance is about discarding limitations and overcoming obstacles. First and foremost, it is a community, bound together by limited mobility, yet stronger for
                it and driven by a quest for health and physical empowerment. Through fitness, motivation and a will to grow and thrive, people can overcome their bodies’
                limitations to be their best selves.
              </Typography> */}
            </Hidden>
            <Hidden mdDown>
              <Typography variant="h1" style={{ color: theme.palette.baseColors.white }}>
                ParaPer4mance GO App
              </Typography>
              {/* <br />
              <Typography variant="h5" style={{ color: theme.palette.baseColors.white }}>
                ParaPer4mance is about discarding limitations and overcoming obstacles. First and foremost, it is a community, bound together by limited mobility, yet stronger for
                it and driven by a quest for health and physical empowerment. Through fitness, motivation and a will to grow and thrive, people can overcome their bodies’
                limitations to be their best selves.
              </Typography> */}
            </Hidden>

            <Divider style={{ marginTop: 15, marginBottom: 15 }} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <video
                  width="100%"
                  height="auto"
                  controls
                  poster="/images/logos/GoAppLogo.svg"
                  style={{ backgroundColor: "#000000", maxWidth: "100%", borderRadius: 4, maxHeight: 500 }}
                >
                  <source src="https://pp4mnc.s3.us-east-2.amazonaws.com/ParaPitchGo2.0.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Grid>
              <Grid item xs={12}>
              {getAppStoreDownloadBtns("secondary",setOpenAppAlert)}
              </Grid>
            </Grid>

            <br />
            {/* <Typography variant="body1">
              “ParaPer4mance was created for me and you: Anyone who is in a wheelchair or who has limitations with mobility. Recently injured, elderly, paraplegic, quadriplegic,
              wheelchair-bound, recent stroke, spina bifida, and other complications that lead to the use of a wheelchair are all encouraged to use ParaPer4mance’s mobile fitness
              routines.”
            </Typography> */}
          </Grid>
        </Grid>
      </Box>

      <div style={{ padding: 20 }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Hidden lgUp>
              <Typography variant="h2">Why An App?</Typography>
              <Typography variant="body1">{whyText}</Typography>

              <Typography variant="body1" style={{ fontWeight: "bold", marginTop: 20 }}>
                Community makes a difference in your recovery
              </Typography>
              {list1}
            </Hidden>
            <Hidden mdDown>
              <Typography variant="h1">Why An App?</Typography>
              <Typography variant="h5">{whyText}</Typography>

              <Typography variant="body1" style={{ fontWeight: "bold", marginTop: 20 }}>
                Community makes a difference in your recovery
              </Typography>
              {list1}
            </Hidden>
          </Grid>
          <Grid item sm={6}>
            <img src="/images/pp4mnc/app1.png" alt="ParaPer4mance GO App" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </div>

      <div className={classes.colorboxBlockAlt}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Hidden lgUp>
              <Typography variant="h2" style={{ color: theme.palette.baseColors.white }}>
                What Is It?
              </Typography>
              <Typography variant="body1" style={{ color: theme.palette.baseColors.white }}>
                {whatText}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "bold", marginTop: 20, color: theme.palette.baseColors.white }}>
                Videos, Community Chat, Nutritional Advice
              </Typography>
              {list2}
            </Hidden>
            <Hidden mdDown>
              <Typography variant="h1" style={{ color: theme.palette.baseColors.white }}>
                What Is It?
              </Typography>
              <Typography variant="h5" style={{ color: theme.palette.baseColors.white }}>
                {whatText}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "bold", marginTop: 20, color: theme.palette.baseColors.white }}>
                Videos, Community Chat, Nutritional Advice
              </Typography>
              {list2}
            </Hidden>
          </Grid>
          <Grid item sm={6}>
            <img src="/images/pp4mnc/app2.png" alt="ParaPer4mance GO App" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </div>

      <div style={{ padding: 20 }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Hidden lgUp>
              <Typography variant="h2">Features:</Typography>
              {list3}
            </Hidden>
            <Hidden mdDown>
              <Typography variant="h1">Features:</Typography>
              {list3}
            </Hidden>
          </Grid>
          <Grid item sm={6}>
            <img src="/images/pp4mnc/app3.png" alt="ParaPer4mance GO App" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </div>

      <Pp4mncFooter />
    </div>
  );
};

export default GoApp;
