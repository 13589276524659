import { createMuiTheme, lighten, darken } from "@material-ui/core/styles";
import { red, grey, pink, green, orange, purple, blue, yellow } from "@material-ui/core/colors";
import createPalette, { PaletteOptions } from "@material-ui/core/styles/createPalette";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    baseColors: {
      white: string;
      black: string;
      success: string;
      danger: string;
      primary: string;
      primaryLight: string;
      primaryLighter: string;
      primaryDark: string;
      primaryHighlight: string;
      secondary: string;
      altSecondary: string;
      secondaryDark: string;
      secondaryLight: string;
      secondaryHighlight: string;
      disabled: string;
      grayBackground: string;
      grayShadow: string;
      grayBorder: string;
      grayIcon: string;
      muiShadow: string;
    };
    uiStatusColors: {
      red: string;
      redLight: string;
      pink: string;
      pinkLight: string;
      green: string;
      greenLight: string;
      orange: string;
      orangeLight: string;
      purple: string;
      purpleLight: string;
      blue: string;
      blueLight: string;
      yellow: string;
      yellowLight: string;
      gray: string;
      grayLight: string;
      skyBlue: string;
    };
  }

  interface PaletteOptions {
    baseColors?: {
      white: string;
      black: string;
      success: string;
      danger: string;
      primary: string;
      primaryLight: string;
      primaryLighter: string;
      primaryDark: string;
      primaryHighlight: string;
      secondary: string;
      altSecondary: string;
      secondaryDark: string;
      secondaryLight: string;
      secondaryHighlight: string;
      disabled: string;
      grayBackground: string;
      grayShadow: string;
      grayBorder: string;
    };
    uiStatusColors?: {
      red: string;
      redLight: string;
      pink: string;
      pinkLight: string;
      green: string;
      greenLight: string;
      orange: string;
      orangeLight: string;
      purple: string;
      purpleLight: string;
      blue: string;
      blueLight: string;
      yellow: string;
      yellowLight: string;
      gray: string;
      grayLight: string;
      skyBlue: string;
    };
  }
}

const primaryBlue = "#0c71c2";
const secondary = "#565656";
const altSecondary = "#049321";

const baseColors = {
  white: "#FFF",
  black: "#000",
  success: green[500],
  danger: red[700],
  primary: primaryBlue,
  primaryLight: lighten(primaryBlue, 0.2),
  primaryLighter: lighten(primaryBlue, 0.5),
  primaryDark: darken(primaryBlue, 0.2),
  primaryHighlight: lighten(primaryBlue, 0.9),
  secondary: secondary,
  altSecondary: altSecondary,
  secondaryDark: lighten(secondary, 0.2),
  secondaryLight: darken(secondary, 0.2),
  secondaryHighlight: lighten(secondary, 0.9),
  disabled: grey[200],
  grayBackground: grey[100],
  grayShadow: "#EAEAEA",
  grayBorder: "#CCC",
  grayIcon: "rgba(0,0,0,0.54)",
  muiShadow: "rgba(57, 85, 120, 0.16)",
};

// Leaving these for reference only
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const itouchColors = {
//   orange: "#CD6B2C",
//   navy: "#062D4C",
//   teal: "#669EB4",
//   gray: "#DFD7CD",
//   green: "#97BBA3",
//   yellow: "#DBC97E",
// };

const uiStatusColors = {
  red: red[500],
  redLight: red[50],
  pink: pink[500],
  pinkLight: pink[50],
  green: green[500],
  greenLight: green[50],
  orange: orange[500],
  orangeLight: orange[50],
  purple: purple[500],
  purpleLight: purple[50],
  blue: blue[500],
  blueLight: blue[50],
  yellow: yellow[500],
  yellowLight: yellow[50],
  gray: grey[500],
  grayLight: grey[50],
  skyBlue: blue[200],
};

// Override MUI Theme variables globally here.
// See: https://material-ui.com/customization/themes/

function createITouchPalette(options: PaletteOptions) {
  return createPalette({
    baseColors: baseColors,
    uiStatusColors: uiStatusColors,
    ...options,
  });
}

const pp4mncPalette = createITouchPalette({
  primary: {
    main: baseColors.primary,
    dark: baseColors.primaryDark,
    light: baseColors.primaryLight,
    contrastText: baseColors.white,
  },
  secondary: {
    main: baseColors.secondary,
    dark: baseColors.secondaryDark,
    light: baseColors.secondaryLight,
    contrastText: baseColors.white,
  },
});

export const commonTheme = createMuiTheme({
  // Example: Change the root font size for entire app to 10px. REM values are now calculated from 10px.
  typography: {
    fontFamily: ["Graphik", "Lato", "Roboto", "Arial", '"Helvetica Neue"', "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
    fontSize: 13,
    // The following are just here for explicitness
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  shape: {
    borderRadius: 4,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: pp4mncPalette,
});

export default commonTheme;
