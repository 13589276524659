// General

import {
  Typography,
  Grid,
  Button,
  IconButton,
  Tooltip,
  Hidden,
} from "@material-ui/core";
import {
  AndroidOutlined,
  Apple,
  Facebook,
  Instagram,
  YouTube,
} from "@material-ui/icons";
import React from "react";

export const openWidth = 300;
export const youtubeURL =
  "https://www.youtube.com/channel/UCbmCzi4Dc_63tpVeMlUASuQ";
export const instagramURL = "https://instagram.com/paraper4mance";
export const facebookURL = "https://www.facebook.com/ParaPer4mance";
export const playStoreURL =
  "https://play.google.com/store/apps/details?id=com.paraper4mance";
export const appleStoreURL =
  "https://apps.apple.com/us/app/paraper4mance-go/id1526187697";

export enum Pages {
  HOME = "home",
  EULA = "eula",
  GO_APP = "goApp",
  CONTACT = "contact",
  ABOUT = "about",
  PROGRAM30 = "cancelled",
  TOU = "tou",
  PRIVACY = "privacy",
}

export const getAppStoreDownloadBtns = (buttonColor,setOpenAppAlert) => {
  return (
    <>
      <Typography
        variant="caption"
        style={{ fontSize: "1.2em", color: "#FFFFFF" }}
      >
        Download our FREE App Today!
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            title="Download in Google Play Store"
            variant="contained"
            color={buttonColor}
            fullWidth
            disableElevation
            onClick={() => {
              //window.open(playStoreURL);
              setOpenAppAlert(true);
            }}
          >
            <Hidden mdUp>
              <AndroidOutlined />
            </Hidden>
            <Hidden smDown>
              <AndroidOutlined style={{ width: "1.5em", height: "auto" }} />
            </Hidden>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            title="Download in Apple Store"
            variant="contained"
            color={buttonColor}
            fullWidth
            disableElevation
            onClick={() => {
              //window.open(appleStoreURL);
              setOpenAppAlert(true);
            }}
          >
            <Hidden mdUp>
              <Apple />
            </Hidden>
            <Hidden smDown>
              <Apple style={{ width: "1.5em", height: "auto" }} />
            </Hidden>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export const getSocialLinks = (styleProps) => {
  return (
    <Grid container style={styleProps}>
      <Grid item xs={4}>
        <Tooltip arrow title="Follow Us On Facebook">
          <IconButton
            color="inherit"
            onClick={() => {
              window.open(facebookURL);
            }}
          >
            <Facebook style={{ width: 30, height: 30 }} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip arrow title="Follow Us On Instagram">
          <IconButton
            color="inherit"
            onClick={() => {
              window.open(instagramURL);
            }}
          >
            <Instagram style={{ width: 30, height: 30 }} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip arrow title="Subscribe To Our YouTube Channel">
          <IconButton
            color="inherit"
            onClick={() => {
              window.open(youtubeURL);
            }}
          >
            <YouTube style={{ width: 30, height: 30 }} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
