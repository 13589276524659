// General
import { makeStyles, createStyles, Theme, Grid, Button, Divider, Typography, useTheme,Snackbar } from "@material-ui/core";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { getAppStoreDownloadBtns } from "shared/utils";
//Common
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContentDiv: {
      overflowX: "hidden",
      // display: "flex",
      // flex: "1 1 auto",
      // flexDirection: "column",
      //height: `calc(100vh - 120px)`,
      //height: "100vh",
      //marginTop: 30,
      //width: "100%",
      //paddingTop: 30,
      //paddingBottom: 30,
      // marginLeft: "auto",
      // marginRight: "auto",
    },
    scrollableFlexDiv: {
      height: "0px",
      padding: theme.spacing(2),
      flex: "2 1 auto",
      overflow: "auto",
      //border: "1px solid " + theme.palette.grey[300],
    },
    thinText: {
      fontFamily: "Arial",
    },
  })
);

interface IProps {
  // openState: boolean;
  // setOpenState: (value: boolean) => void;
}

const Pp4mncFooter: React.FC<IProps> = (props) => {
  //const { openState, setOpenState } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [redirectToEULA, setRedirectToEULA] = useState(false);
  const [redirectToPrivacyPolicy, setRedirectToPrivacyPolicy] = useState(false);
  const [redirectToTermsOfUse, setRedirectToTermsOfUse] = useState(false);
  const [openAppAlert,setOpenAppAlert] = useState(false);

  return (
    <div style={{ backgroundColor: theme.palette.secondary.main, padding: 20, color: theme.palette.baseColors.white, marginTop: 30, paddingBottom: 0 }}>
      <Snackbar open={openAppAlert} autoHideDuration={6000} onClose={()=>{setOpenAppAlert(false)}} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
  <Alert onClose={()=>{setOpenAppAlert(false)}} severity="success">
    We are currently reworking the app to bring you a better user experience. Follow us on social media for updates!
  </Alert>
</Snackbar>
      {redirectToEULA && <Redirect push to="/eula" />}
      {redirectToPrivacyPolicy && <Redirect push to="/privacy-policy" />}
      {redirectToTermsOfUse && <Redirect push to="/terms-of-use" />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img src="/images/logos/ParaPer4mance.svg" alt="ParaPer4mance" style={{ width: "50%" }} />
          <br />
          <Typography variant="caption" className={classes.thinText}>
            © 2021 ParaPer4mance Inc. All rights reserved. ParaPer4mance, LLC is the owner of the ParaPer4mance and Team ParaPer4mance trademarks, and all related designs,
            trademarks, copyrights, and other intellectual property.
          </Typography>
          <Divider style={{ marginBottom: 20 }} />

          {getAppStoreDownloadBtns("primary",setOpenAppAlert)}

          <Divider style={{ marginBottom: 20 }} />
          <Typography variant="caption">Website Disclaimer</Typography>
          <Divider />
          <Typography variant="caption" className={classes.thinText}>
            ∗These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease. †Results
            vary depending on starting point, goals, and effort. Exercise and proper diet are necessary to achieve and maintain weight loss and muscle definition. The testimonials
            featured may have used more than one ParaPer4mance product or extended the program to achieve their maximum results. Consult your physician and follow all safety
            instructions before beginning any exercise program or nutrition plan, or using any supplement or meal replacement product, especially if you are pregnant,
            breastfeeding, have any medical condition, or are taking any medication. The contents on our website are for informational purposes only, and are not intended to
            diagnose any medical condition, replace the advice of a healthcare professional, or provide any medical advice, diagnosis, or treatment.
          </Typography>

          <Grid container style={{ textAlign: "center", marginTop: 20 }}>
            <Grid item xs={4}>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  setRedirectToPrivacyPolicy(true);
                }}
                className={classes.thinText}
                style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
              >
                Privacy Policy
              </Button>
            </Grid>
            <Grid item xs={4} style={{ borderLeft: "1px solid #FFFFFF", borderRight: "1px solid #FFFFFF" }}>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  setRedirectToTermsOfUse(true);
                }}
                className={classes.thinText}
                style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
              >
                Terms Of Use
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  setRedirectToEULA(true);
                }}
                className={classes.thinText}
                style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
              >
                EULA
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Pp4mncFooter;
