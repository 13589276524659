// General
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
  Box,
  Grid,
  Typography,
  Hidden,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Pp4mncDrawer from "shared/components/Pp4mncDrawer";
import Pp4mncAppBar from "shared/components/Pp4mncAppBar";
import Pp4mncFooter from "shared/components/Pp4mncFooter";
import { Pages } from "shared/utils";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";
import { downloadFileFromUrl } from "shared/download_utils";
import Alert from "@material-ui/lab/Alert";
//Common

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: 40,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    loadingLogo: {
      width: 200,
      position: "absolute",
      top: window.innerHeight / 2 - 100 + "px",
      left: window.innerWidth / 2 - 100 + "px",
    },
    mainContentDiv: {
      overflow: "hidden",
    },
    colorBoxHeader: {
      padding: 20,
      paddingTop: 100,
      backgroundColor: theme.palette.baseColors.primary,
    },
    colorboxBlockAlt: {
      padding: 20,
      backgroundColor: theme.palette.baseColors.secondary,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    thinText: {
      fontFamily: "Arial",
    },
    myFieldset: {
      fontFamily: theme.typography.fontFamily,
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
      marginTop: 20,
    },
  })
);

interface IProps {
  //   //logOut: () => void;
  //   authUser: any;
  //   productList: Product[];
  //   productTypeDetails: any[];
  //   setProductTypeDetails: (newPtd: any[]) => void;
  //   clearCart?: boolean;
}

const Program30Day: React.FC<IProps> = (props) => {
  //const { productList, productTypeDetails, setProductTypeDetails } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const [errorDetail, setErrorDetail] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [otherCategories, setOtherCategories] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [optimistic, setOptimistic] = useState("Sometimes");
  const [relaxed, setRelaxed] = useState("Sometimes");
  const [thinkingClearly, setThinkingClearly] = useState("Sometimes");
  const [closeToPpl, setCloseToPpl] = useState("Sometimes");
  const [useful, setUseful] = useState("Sometimes");
  const [dealWithProbs, setDealWithProbs] = useState("Sometimes");
  const [makeUpMyMind, setMakeUpMyMind] = useState("Sometimes");

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [streetAddressError, setStreetAddressError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [categoriesError, setCategoriesError] = useState(false);

  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const getStyles = (name, selectedCategories, theme) => {
    return {
      fontFamily: selectedCategories.indexOf(name) === -1 ? "Arial" : theme.typography.fontFamily,
    };
  };

  const handleChange = (event) => {
    setSelectedCategories(event.target.value);
  };

  useEffect(() => {
    if (document.title !== "ParaPer4mance 30 Day Program") {
      document.title = "ParaPer4mance 30 Day Program";
      window.scrollTo(0, 0);
    }
  });

  const downloadPDF = () => {
    downloadFileFromUrl("/download/30DayProgram.pdf", "ParaPer4mance30DayProgram.pdf").then(() => {
      resetForm();
    });
  };

  const resetForm = () => {
    setOpenSuccess(false);
    setOpenError(false);

    setErrorDetail("");

    setFirstName("");
    setLastName("");
    setEmail("");
    setStreetAddress("");
    setCity("");
    setState("");
    setZipCode("");

    setOtherCategories("");
    setSelectedCategories([]);

    setOptimistic("Sometimes");
    setRelaxed("Sometimes");
    setThinkingClearly("Sometimes");
    setCloseToPpl("Sometimes");
    setUseful("Sometimes");
    setDealWithProbs("Sometimes");
    setMakeUpMyMind("Sometimes");

    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);
    setStreetAddressError(false);
    setCityError(false);
    setStateError(false);
    setZipError(false);
    setCategoriesError(false);

    setRecaptchaVerified(false);
    setFormSubmitted(false);
  };

  const sendEmail = (event) => {
    event.preventDefault();

    !firstName ? setFirstNameError(true) : setFirstNameError(false);
    !lastName ? setLastNameError(true) : setLastNameError(false);
    !email ? setEmailError(true) : setEmailError(false);
    !streetAddress ? setStreetAddressError(true) : setStreetAddressError(false);
    !city ? setCityError(true) : setCityError(false);
    !state ? setStateError(true) : setStateError(false);
    !zipCode ? setZipError(true) : setZipError(false);
    !selectedCategories ? setCategoriesError(true) : setCategoriesError(false);

    if (firstName && lastName && email && streetAddress && city && state && zipCode && selectedCategories) {
      emailjs.sendForm("service_hzffsns", "template_tllia5b", window.document.forms[0], "user_xGyVBItXjzNAApDSVp5RZ").then(
        (result) => {
          setErrorDetail("");
          setOpenSuccess(true);
          setFormSubmitted(true);
        },
        (error) => {
          setErrorDetail(error.text);
          setOpenError(true);
        }
      );
    } else {
      console.log("ERROR");
    }
  };

  const categoryOptions = [
    { title: "Social & Community", value: "Social & Community" },
    { title: "Rehabilitation & Recovery", value: "Rehabilitation & Recovery" },
    { title: "Fitness & Nutrition", value: "Fitness & Nutrition" },
    { title: "Lifestyle & Education", value: "Lifestyle & Education" },
  ];

  const scrollToReg = () => {
    const y = window.document.getElementById("registrationSection")?.getBoundingClientRect()?.top || 0 + window.scrollY;
    console.log("ScrollTO: " + y);
    y &&
      window.scroll({
        top: y - 100,
        behavior: "smooth",
      });
  };

  return (
    <div className={classes.mainContentDiv}>
      <Pp4mncAppBar openState={open} setOpenState={setOpen} />
      <Pp4mncDrawer currentPage={Pages.PROGRAM30} openState={open} setOpenState={setOpen} />
      <Box display="flex" flexDirection="column" flex="1" flexGrow="1" className={classes.colorBoxHeader}>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Hidden lgUp>
              <Typography variant="h2" style={{ color: theme.palette.baseColors.white }}>
                FREE 30 Day Program
              </Typography>
              <Typography variant="body1" style={{ color: theme.palette.baseColors.white }}>
                By Joining Our 30 Day Program You Get FREE Access To:
                <br />
                <ul>
                  <li>ParaPer4mance GO Mobile App</li>
                  <li>Exclusive Community</li>
                  <li>Access to ParaPer4mance Elite Coaches</li>
                  <li>Downloadable 30 Day Wellness Calendar PDF</li>
                </ul>
              </Typography>
            </Hidden>
            <Hidden mdDown>
              <Typography variant="h1" style={{ color: theme.palette.baseColors.white }}>
                FREE 30 Day Program
              </Typography>
              <Typography variant="h6" style={{ color: theme.palette.baseColors.white }}>
                By Joining Our 30 Day Program You Get FREE Access To:
                <br />
                <ul>
                  <li>ParaPer4mance GO Mobile App</li>
                  <li>Exclusive Community</li>
                  <li>Access to ParaPer4mance Elite Coaches</li>
                  <li>Downloadable 30 Day Wellness Calendar PDF</li>
                </ul>
              </Typography>
            </Hidden>
            <Button
              variant="contained"
              onClick={() => {
                setOpenDialog(true);
              }}
              fullWidth
            >
              Click Here For More Details
            </Button>
            <Dialog
              open={openDialog}
              onClose={() => {
                setOpenDialog(false);
                scrollToReg();
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"FREE 30 Day Program"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <ul className={classes.thinText}>
                    <li>
                      ParaPer4mance GO Mobile App: <br />A social wellness app designed exclusively for wheelchair users, limited mobility and care trackers. The added features and
                      Elite coaches are proven to increase the quality of life for all of our users.{" "}
                    </li>
                  </ul>
                  <ul className={classes.thinText}>
                    <li>
                      Exclusive Community: <br />
                      Chat, meet, learn and connect with wheelchair users around the world using our exclusive mobile app (ParaPer4mance GO) , Private Facebook group (ParaPer4mance
                      Elite) and weekly zoom calls.{" "}
                    </li>
                  </ul>
                  <ul className={classes.thinText}>
                    <li>
                      Access to ParaPer4mance Elite Coaches:
                      <ul>
                        <li>Nutrition Coach- Brooke Evans, MS, RDN, LDN</li>
                        <li>Mental Health Coach - Serena Macleod MSc, MBPsS, DipSW</li>
                        <li>Mentor &amp; success Coach- Kenneth Keitt Founder of ParaPer4mance and USMC Combat Veteran</li>
                        <li>Fitness &amp; Condition Coach- Tom Tice Founder of Warrior Strong Inc</li>
                      </ul>
                    </li>
                  </ul>
                  <ul className={classes.thinText}>
                    <li>
                      Downloadable 30 Day Wellness Calendar PDF: <br />
                      We designed a 30 Day mental and physical wellness calendar to help stay on track and organized. We know that you are more likely to follow through with visual
                      support so we created a day by day guide to propel you into the best version of yourself.
                    </li>
                  </ul>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenDialog(false);
                    scrollToReg();
                  }}
                  color="primary"
                  autoFocus
                  variant="contained"
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <img src="/images/pp4mnc/archer.jpeg" alt="Adaptive Fitness" style={{ width: "100%", borderRadius: 8, marginTop: 10 }} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <img src="/images/pp4mnc/tireHammer.jpg" alt="Adaptive Fitness" style={{ width: "100%", borderRadius: 8 }} />
          </Grid>
        </Grid>
      </Box>
      <div style={{ padding: 20 }} id="registrationSection">
        <Typography variant="h6">Once you submit this form you will be given access to a download button which will provide further instructions</Typography>
        <Typography variant="h6" color="error">
          * Required Field
        </Typography>
        <form id="contactUsForm">
          <fieldset className={classes.myFieldset}>
            <legend>Registration Information:</legend>
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="* First Name"
                  variant="outlined"
                  color="primary"
                  name="firstName"
                  id="firstName"
                  fullWidth
                  value={firstName}
                  onChange={(event) => {
                    const value = event.target.value;
                    value.includes(",") && value.replaceAll(",", "-");
                    setFirstName(value);
                  }}
                  error={firstNameError}
                  //helperText="Your Name (Required)"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="* Last Name"
                  variant="outlined"
                  color="primary"
                  name="lastName"
                  id="lastName"
                  fullWidth
                  value={lastName}
                  onChange={(event) => {
                    const value = event.target.value;
                    value.includes(",") && value.replaceAll(",", "-");
                    setLastName(value);
                  }}
                  //style={{ marginTop: 20 }}
                  error={lastNameError}
                  //helperText="Your Name (Required)"
                />
              </Grid>
            </Grid>

            <TextField
              label="* Email Address"
              variant="outlined"
              color="primary"
              name="email"
              id="email"
              fullWidth
              style={{ marginTop: 20 }}
              value={email}
              onChange={(event) => {
                const value = event.target.value;
                value.includes(",") && value.replaceAll(",", "-");
                setEmail(value);
              }}
              error={emailError}
              //helperText="Your Email Address (Required)"
            />
            <Grid container spacing={2} style={{ marginTop: 20 }}>
              <Grid item sm={3} xs={12}>
                <TextField
                  label="* Street Address"
                  variant="outlined"
                  color="primary"
                  name="streetAddress"
                  id="streetAddress"
                  fullWidth
                  //style={{ marginTop: 20 }}
                  value={streetAddress}
                  onChange={(event) => {
                    const value = event.target.value;
                    value.includes(",") && value.replaceAll(",", "-");
                    setStreetAddress(value);
                  }}
                  error={streetAddressError}
                  //helperText="Your Email Address (Required)"
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <TextField
                  label="* City"
                  variant="outlined"
                  color="primary"
                  name="city"
                  id="city"
                  fullWidth
                  //style={{ marginTop: 20 }}
                  value={city}
                  onChange={(event) => {
                    const value = event.target.value;
                    value.includes(",") && value.replaceAll(",", "-");
                    setCity(value);
                  }}
                  error={cityError}
                  //helperText="Your Email Address (Required)"
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <TextField
                  label="* State"
                  variant="outlined"
                  color="primary"
                  name="state"
                  id="state"
                  fullWidth
                  //style={{ marginTop: 20 }}
                  value={state}
                  onChange={(event) => {
                    const value = event.target.value;
                    value.includes(",") && value.replaceAll(",", "-");
                    setState(value);
                  }}
                  error={stateError}
                  //helperText="Your Email Address (Required)"
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <TextField
                  label="* Zip Code"
                  variant="outlined"
                  color="primary"
                  name="zipCode"
                  id="zipCode"
                  fullWidth
                  //style={{ marginTop: 20 }}
                  value={zipCode}
                  onChange={(event) => {
                    const value = event.target.value;
                    value.includes(",") && value.replaceAll(",", "-");
                    setZipCode(value);
                  }}
                  error={zipError}
                  //helperText="Your Email Address (Required)"
                />
              </Grid>
            </Grid>
          </fieldset>
          <fieldset className={classes.myFieldset}>
            <legend>Tell Us About Your Interests:</legend>
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item sm={6} xs={12}>
                <Autocomplete
                  multiple
                  options={categoryOptions}
                  getOptionLabel={(option) => option.title}
                  defaultValue={selectedCategories}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Categories Important To Me" helperText="Select One Or More" />}
                />

                {/* <FormControl fullWidth>
                  <InputLabel>* Categories Important To You:</InputLabel>
                  <Select
                    multiple
                    value={selectedCategories}
                    onChange={handleChange}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected: any) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} className={classes.chip} />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                    error={categoriesError}
                    id="selectedCategories"
                    name="selectedCategories"
                  >
                    {["Social & Community", "Rehabilitation & Recovery", "Fitness & Nutrition", "Lifestyle & Education"].map((name) => (
                      <MenuItem key={name} value={name} style={getStyles(name, selectedCategories, theme)}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Other Categories"
                  variant="outlined"
                  color="primary"
                  name="otherCategories"
                  id="otherCategories"
                  fullWidth
                  //style={{ marginTop: 20 }}
                  value={otherCategories}
                  onChange={(event: any) => {
                    setOtherCategories(event.target.value);
                  }}
                  //error={emailError}
                  //helperText="Your Email Address (Required)"
                />
              </Grid>
            </Grid>
          </fieldset>
          <fieldset className={classes.myFieldset}>
            <legend>Tell us about how you are feeling:</legend>
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item sm={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="optimistic-label">I've been feeling optimistic about the future</InputLabel>
                  <Select
                    labelId="optimistic-label"
                    id="optimistic"
                    name="optimistic"
                    value={optimistic}
                    onChange={(event: any) => {
                      setOptimistic(event.target.value);
                    }}
                    label="I've been feeling optimistic about the future"
                  >
                    <MenuItem value="Never">Never [0%]</MenuItem>
                    <MenuItem value="Rarely">Rarely [1% - 20%]</MenuItem>
                    <MenuItem value="Sometimes">Sometimes [20% - 60%] </MenuItem>
                    <MenuItem value="Often">Often [61% - 90%] </MenuItem>
                    <MenuItem value="Always">Always [91% - 100%]</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="useful-label">I've been feeling useful</InputLabel>
                  <Select
                    labelId="useful-label"
                    id="useful"
                    name="useful"
                    value={useful}
                    onChange={(event: any) => {
                      setUseful(event.target.value);
                    }}
                    label="I've been feeling useful"
                  >
                    <MenuItem value="Never">Never [0%]</MenuItem>
                    <MenuItem value="Rarely">Rarely [1% - 20%]</MenuItem>
                    <MenuItem value="Sometimes">Sometimes [20% - 60%] </MenuItem>
                    <MenuItem value="Often">Often [61% - 90%] </MenuItem>
                    <MenuItem value="Always">Always [91% - 100%]</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">I've been feeling relaxed</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="relaxed"
                    name="relaxed"
                    value={relaxed}
                    onChange={(event: any) => {
                      setRelaxed(event.target.value);
                    }}
                    label="I've been feeling relaxed"
                  >
                    <MenuItem value="Never">Never [0%]</MenuItem>
                    <MenuItem value="Rarely">Rarely [1% - 20%]</MenuItem>
                    <MenuItem value="Sometimes">Sometimes [20% - 60%] </MenuItem>
                    <MenuItem value="Often">Often [61% - 90%] </MenuItem>
                    <MenuItem value="Always">Always [91% - 100%]</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">I've been dealing with problems well</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="dealWithProbs"
                    name="dealWithProbs"
                    value={dealWithProbs}
                    onChange={(event: any) => {
                      setDealWithProbs(event.target.value);
                    }}
                    label="I've been dealing with problems well"
                  >
                    <MenuItem value="Never">Never [0%]</MenuItem>
                    <MenuItem value="Rarely">Rarely [1% - 20%]</MenuItem>
                    <MenuItem value="Sometimes">Sometimes [20% - 60%] </MenuItem>
                    <MenuItem value="Often">Often [61% - 90%] </MenuItem>
                    <MenuItem value="Always">Always [91% - 100%]</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">I've been thinking clearly</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="thinkingClearly"
                    name="thinkingClearly"
                    value={thinkingClearly}
                    onChange={(event: any) => {
                      setThinkingClearly(event.target.value);
                    }}
                    label="I've been thinking clearly"
                  >
                    <MenuItem value="Never">Never [0%]</MenuItem>
                    <MenuItem value="Rarely">Rarely [1% - 20%]</MenuItem>
                    <MenuItem value="Sometimes">Sometimes [20% - 60%] </MenuItem>
                    <MenuItem value="Often">Often [61% - 90%] </MenuItem>
                    <MenuItem value="Always">Always [91% - 100%]</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">I've been able to make up my own mind about things</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="makeUpMyMind"
                    name="makeUpMyMind"
                    value={makeUpMyMind}
                    onChange={(event: any) => {
                      setMakeUpMyMind(event.target.value);
                    }}
                    label="I've been able to make up my own mind about things"
                  >
                    <MenuItem value="Never">Never [0%]</MenuItem>
                    <MenuItem value="Rarely">Rarely [1% - 20%]</MenuItem>
                    <MenuItem value="Sometimes">Sometimes [20% - 60%] </MenuItem>
                    <MenuItem value="Often">Often [61% - 90%] </MenuItem>
                    <MenuItem value="Always">Always [91% - 100%]</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">I've been feeling close to other people</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="closeToPpl"
                    name="closeToPpl"
                    value={closeToPpl}
                    onChange={(event: any) => {
                      setCloseToPpl(event.target.value);
                    }}
                    label="I've been feeling close to other people"
                  >
                    <MenuItem value="Never">Never [0%]</MenuItem>
                    <MenuItem value="Rarely">Rarely [1% - 20%]</MenuItem>
                    <MenuItem value="Sometimes">Sometimes [20% - 60%] </MenuItem>
                    <MenuItem value="Often">Often [61% - 90%] </MenuItem>
                    <MenuItem value="Always">Always [91% - 100%]</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </fieldset>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <ReCAPTCHA
            id="program30"
            sitekey="6LfBPTUbAAAAAKMJzj04EIfmdckS1h940ovs91Bj"
            onChange={(value) => {
              value && setRecaptchaVerified(true);
            }}
            onExpired={() => {
              setRecaptchaVerified(false);
            }}
            onErrored={() => {
              setRecaptchaVerified(false);
            }}
          />
          <Button fullWidth color="primary" type="button" disabled={!recaptchaVerified && !formSubmitted} onClick={sendEmail} variant="contained" style={{ marginTop: 20 }}>
            1) Submit Registration
          </Button>

          <Button
            fullWidth
            type="button"
            disabled={!formSubmitted}
            onClick={downloadPDF}
            variant="contained"
            style={formSubmitted ? { marginTop: 20, backgroundColor: theme.palette.baseColors.altSecondary, color: theme.palette.baseColors.white } : { marginTop: 20 }}
          >
            2) Download The Program!
          </Button>
        </form>
      </div>

      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSuccess(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenSuccess(false);
          }}
          severity="success"
        >
          Thanks! Your message was successfully sent!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={() => {
          setOpenError(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenError(false);
          }}
          severity="error"
        >
          Oops. It seems we've run into an error. Please notify us on our 'Contact Us' page. Thank you!
          <br />
          Detail:{" "}
          <Typography variant="caption" className={classes.thinText}>
            {errorDetail}
          </Typography>
        </Alert>
      </Snackbar>
      <Pp4mncFooter />
    </div>
  );
};

export default Program30Day;
