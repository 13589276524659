// General
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  IconButton,
  Tooltip,
  AppBar,
  Toolbar,
  Hidden,
  Popover,
  Snackbar
} from "@material-ui/core";
import React,{useState} from "react";
import { AndroidOutlined, Apple } from "@material-ui/icons";
import { MenuIcon } from "shared/components/Icons";
import clsx from "clsx";
import { openWidth, playStoreURL, appleStoreURL } from "shared/utils";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


//Common

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: 0,
      //backgroundColor: theme.palette.baseColors.primaryLighter,
      //backgroundColor: theme.palette.baseColors.secondary,
      backgroundColor: theme.palette.baseColors.primary,
    },

    appBarShift: {
      width: `calc(100% - ${openWidth}px)`,
      marginLeft: openWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  })
);

interface IProps {
  openState: boolean;
  setOpenState: (value: boolean) => void;
}

const Pp4mncAppBar: React.FC<IProps> = (props) => {
  const { openState, setOpenState } = props;
  const classes = useStyles();
const [openAppAlert,setOpenAppAlert] = useState(false);
  return (
    <AppBar
      elevation={0}
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: openState,
      })}
    >
    
    <Snackbar open={openAppAlert} autoHideDuration={6000} onClose={()=>{setOpenAppAlert(false)}} anchorOrigin={ {vertical: 'top', horizontal: 'center'} }>
  <Alert onClose={()=>{setOpenAppAlert(false)}} severity="success">
    We are currently reworking the app to bring you a better user experience. Follow us on social media for updates!
  </Alert>
</Snackbar>
    
      
      <Toolbar>
        <Grid container style={{ margin: 4 }}>
          <Grid item xs={3} style={{ paddingTop: 8 }}>
            <IconButton
              color="inherit"
              onClick={() => {
                setOpenState(!openState);
              }}
            >
              <Hidden mdUp>
                <MenuIcon />
              </Hidden>
              <Hidden smDown>
                <MenuIcon style={{ width: "1.5em", height: "auto" }} />
              </Hidden>
            </IconButton>
          </Grid>
          <Grid item xs={5}>
            <div style={{ textAlign: "center" }}>
              <a href="/">
                <img
                  src="/images/logos/ParaPer4mancew.svg"
                  alt="ParaPer4mance"
                  style={{
                    maxHeight: 50,
                    height: "100%",
                    maxWidth: "100%",
                    position: "relative",
                    top: 10,
                    left: 5,
                  }}
                />
              </a>
            </div>
          </Grid>
          <Grid item xs={4} style={{ paddingTop: 4, textAlign: "right" }}>
            <Tooltip
              arrow
              title="Download our FREE app, 'ParaPer4mance GO' from the Google Play Store"
            >
              <IconButton
                color="inherit"
                onClick={() => {
                  //alert("We are currently reworking the app to bring you a better user experience. Follow us on social media for updates!");
                  //window.open(playStoreURL);
                  //setShowAndroidAlert(!showAndroidAlert);
                  setOpenAppAlert(true);
                }}
              >
                <Hidden mdUp>
                  <AndroidOutlined />
                </Hidden>
                <Hidden smDown>
                  <AndroidOutlined style={{ width: "1.5em", height: "auto" }} />
                </Hidden>
              </IconButton>
            </Tooltip>
            <Tooltip
              arrow
              title="Download our FREE app, 'ParaPer4mance GO' from the Apple Store"
            >
              <IconButton
                color="inherit"
                onClick={() => {
                  setOpenAppAlert(true);
                  //setShowAppleAlert(!showAppleAlert);
                  //alert("We are currently reworking the app to bring you a better user experience. Follow us on social media for updates!");
                  //window.open(appleStoreURL);
                }}
              >
                <Hidden mdUp>
                  <Apple />
                </Hidden>
                <Hidden smDown>
                  <Apple style={{ width: "1.5em", height: "auto" }} />
                </Hidden>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Pp4mncAppBar;
