// General
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Box,
  useTheme,
  Typography,
  Hidden,
  Divider,
  Snackbar,
  
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
//import { MenuIcon, NavPreviousIcon, ExpandDownIcon, ExpandUpIcon, StarIcon } from "shared/components/Icons";
//import Carousel from "react-material-ui-carousel";
import Pp4mncDrawer from "shared/components/Pp4mncDrawer";
import Pp4mncAppBar from "shared/components/Pp4mncAppBar";
import { Pages } from "shared/utils";
import Pp4mncFooter from "shared/components/Pp4mncFooter";
import { getAppStoreDownloadBtns } from "shared/utils";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
//Common
const carouselHeight = 400;
//const bannerHeight = 370;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    carousel: {
      display: "block",
      height: carouselHeight,
      position: "relative",
      left: 2,
      padding: 0,
      margin: 0,
    },
    colorboxBg: {
      marginTop: 50,
      marginLeft: "auto",
      marginRight: "auto",
      //backgroundColor: theme.palette.baseColors.primaryLighter,
      backgroundColor: theme.palette.baseColors.primary,
      overflow: "visible",
      width: "80%",
      height: "50%",
      borderRadius: 10,
    },
    colorboxBlockAlt: {
      padding: 20,
      backgroundColor: theme.palette.baseColors.secondary,
    },
    colorBoxHeader: {
      padding: 20,
      paddingTop: 100,
      //backgroundColor: theme.palette.baseColors.primaryLighter,
      backgroundColor: theme.palette.baseColors.primary,
      // backgroundImage: `linear-gradient(to bottom,
      //   ${theme.palette.baseColors.primaryLighter},
      //   ${theme.palette.baseColors.primaryLighter} 85%,
      //   rgba(255,255,255,1) 100%)`,
    },
    mainContentDiv: {
      overflow: "hidden",
    },
    text90: {
      width: "90%",
    },
  })
);

interface IProps {
  //   //logOut: () => void;
  //   authUser: any;
  //   productList: Product[];
  //   productTypeDetails: any[];
  //   setProductTypeDetails: (newPtd: any[]) => void;
  //   clearCart?: boolean;
}

const Home: React.FC<IProps> = (props) => {
  //const { productList, productTypeDetails, setProductTypeDetails } = props;
  const classes = useStyles();
  const theme = useTheme();

  const tstamp = Date.now();

  const [open, setOpen] = useState(false);
  const [openAppAlert,setOpenAppAlert] = useState(false);

  const communityImg = useRef("");

  useEffect(() => {
    if (document.title !== "ParaPer4mance") {
      document.title = "ParaPer4mance";
      window.scrollTo(0, 0);
    }
  });

  communityImg.current =
    tstamp % 2 === 1
      ? "/images/banners/banner1.jpg"
      : "/images/banners/banner5.jpg";

  const getIntroSection = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        flexGrow="1"
        className={classes.colorBoxHeader}
      >
        <Grid container alignItems="center" justify="center">
          <Grid item sm={6}>
            <Hidden mdUp>
              <Typography
                variant="h3"
                style={{ color: theme.palette.baseColors.black,fontWeight: 600 }}
              >
                Connecting Wheelchair Users
              </Typography>
              <Typography
                variant="h3"
                style={{ color: theme.palette.baseColors.white }}
              >
                Around The World
              </Typography>
              <Typography
                variant="body1"
                style={{ color: theme.palette.baseColors.black,fontWeight: 600 }}
              >
                Meet, Chat, And Learn From Like Minded People Around The World
              </Typography>
            </Hidden>
            <Hidden smDown>
              <Typography
                variant="h2"
                style={{ color: theme.palette.baseColors.black,fontWeight: 600 }}
              >
                Connecting Wheelchair Users
              </Typography>
              <Typography
                variant="h2"
                style={{ color: theme.palette.baseColors.white }}
              >
                Around The World
              </Typography>
              <Typography
                variant="h4"
                style={{ color: theme.palette.baseColors.black,fontWeight: 600 }}
              >
                Meet, Chat, And Learn From Like Minded People Around The World
              </Typography>
            </Hidden>

            {getAppStoreDownloadBtns("secondary",setOpenAppAlert)}
          </Grid>
          <Grid item sm={6}>
            <img
              src="/images/pp4mnc/Main-mock-web.png"
              alt="ParaPer4mance GO App"
              style={{
                display: "block",
                maxWidth: "90%",
                maxHeight: 500,
                width: "auto",
                height: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const getAppSection = () => {
    return (
      <div>
        <div className={classes.colorboxBlockAlt}>
          <div style={{ textAlign: "center" }}>
            <img
              src="/images/logos/GoAppLogo.svg"
              alt="ParaPer4mance GO App Logo"
              style={{ width: "60%" }}
            />
          </div>
          <Grid container>
            <Grid item sm={2} xs={1}>
              &nbsp;
            </Grid>
            <Grid item sm={8} xs={10}>
              {/* <Typography variant="body1" style={{ color: theme.palette.baseColors.white, marginTop: 20 }}>
                Our physical movement may be "limited" by different factors, but our mindset and capabilities together with <strong>ParaPer4mance GO</strong> are limitless! We are
                joined together by the common goal of not missing out on what this world has to offer and what we can contribute to it.
              </Typography>
              <br /> */}
              <Typography
                variant="body1"
                style={{ color: theme.palette.baseColors.white }}
              >
                <strong>ParaPer4mance GO</strong> is the pathway that enables
                you to overcome your present challenges and connect with your
                future success.
              </Typography>
            </Grid>
            <Grid item sm={2} xs={1}>
              &nbsp;
            </Grid>
          </Grid>
        </div>
        {/* <Typography variant="h2" style={{ textAlign: "center", marginTop: 50 }} color="secondary">
          ParaPer4mance GO App
        </Typography> */}
        <Grid container style={{ marginTop: 30 }}>
          <Grid item lg={4} md={6} sm={12}>
            <Hidden lgUp>
              <Typography
                variant="h2"
                style={{ textAlign: "center" }}
                color="secondary"
              >
                Community
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                color="secondary"
              >
                Join others around the globe supporting one another.
              </Typography>
            </Hidden>
            <Hidden mdDown>
              <Typography
                variant="h2"
                style={{ textAlign: "center" }}
                color="secondary"
              >
                Community
              </Typography>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                color="secondary"
              >
                Join others around the globe supporting one another.
              </Typography>
            </Hidden>

            <div className={classes.colorboxBg}>
              <img
                src="/images/pp4mnc/Community.png"
                alt="ParaPer4mance GO App Community"
                style={{
                  width: "150%",
                  position: "relative",
                  left: "-25%",
                  top: "-25%",
                }}
              />
            </div>
          </Grid>

          <Grid item lg={4} md={6} sm={12}>
            <Hidden lgUp>
              <Typography
                variant="h2"
                style={{ textAlign: "center" }}
                color="secondary"
              >
                Programs
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                color="secondary"
              >
                Unlimited access to our catalog of programs designed by
                professionals
              </Typography>
            </Hidden>
            <Hidden mdDown>
              <Typography
                variant="h2"
                style={{ textAlign: "center" }}
                color="secondary"
              >
                Programs
              </Typography>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                color="secondary"
              >
                Unlimited access to our catalog of programs designed by
                professionals
              </Typography>
            </Hidden>

            <div className={classes.colorboxBg}>
              <img
                src="/images/pp4mnc/Programs.png"
                alt="ParaPer4mance GO App Programs"
                style={{
                  width: "150%",
                  position: "relative",
                  left: "-25%",
                  top: "-25%",
                }}
              />
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={12}>
            <Hidden lgUp>
              <Typography
                variant="h2"
                style={{ textAlign: "center" }}
                color="secondary"
              >
                ParaPoints
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                color="secondary"
              >
                Earn rewards for being a part of the community and engaging with
                others.
              </Typography>
            </Hidden>
            <Hidden mdDown>
              <Typography
                variant="h2"
                style={{ textAlign: "center" }}
                color="secondary"
              >
                ParaPoints
              </Typography>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                color="secondary"
              >
                Earn rewards for being a part of the community and engaging with
                others.
              </Typography>
            </Hidden>

            <div className={classes.colorboxBg}>
              <img
                src="/images/pp4mnc/ParaPoints.png"
                alt="ParaPer4mance GO App Para Points"
                style={{
                  width: "150%",
                  position: "relative",
                  left: "-25%",
                  top: "-25%",
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  const getAdditionalInfoSection = () => {
    return (
      <div
        style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.baseColors.white,
          padding: 30,
          textAlign: "center",
        }}
      >
        <Hidden xlUp>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <Typography variant="h2">3,300,000</Typography>
              <Divider />
              <Typography variant="body1">
                # of people who use wheelchairs in the US
              </Typography>
            </Grid>
            <Grid
              item
              sm={4}
              xs={12}
              style={{
                borderLeft: `1px solid ${theme.palette.baseColors.white}`,
                borderRight: `1px solid ${theme.palette.baseColors.white}`,
              }}
            >
              <Typography variant="h2">2,000,000</Typography>
              <Divider />
              <Typography variant="body1">
                # of people who rarely leave their home due to lack of
                accessibility
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="h2">17,000</Typography>
              <Divider />
              <Typography variant="body1">
                # of new Spinal Cord Injury (SCI) Cases Every Year
              </Typography>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden lgDown>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <Typography variant="h1">3,300,000</Typography>
              <Divider />
              <Typography variant="h5">
                # of people who use wheelchairs in the US
              </Typography>
            </Grid>
            <Grid
              item
              sm={4}
              xs={12}
              style={{
                borderLeft: `1px solid ${theme.palette.baseColors.white}`,
                borderRight: `1px solid ${theme.palette.baseColors.white}`,
              }}
            >
              <Typography variant="h1">2,000,000</Typography>
              <Divider />
              <Typography variant="h5">
                # of people who rarely leave their home due to lack of
                accessibility
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="h1">2,000,000</Typography>
              <Divider />
              <Typography variant="h5">
                # of new Spinal Cord Injury (SCI) Cases Every Year
              </Typography>
            </Grid>
          </Grid>
        </Hidden>
      </div>
    );
  };

  // const bannerItems = [
  //   {
  //     title: "CODY",
  //     color: null,
  //     content: (
  //       <div
  //         style={{
  //           width: "100%",
  //           height: bannerHeight,
  //           //backgroundImage: `url("/images/pp4mnc/papa-model-01@2x.png")`,
  //           backgroundImage: `url("/images/pp4mnc/CodyCycle.jpg")`,
  //           backgroundPosition: "center top",
  //           backgroundSize: "cover",
  //           maxWidth: "100%",
  //           boxShadow: "0 0 8px 8px #111111 inset",
  //           backgroundRepeat: "no-repeat",
  //         }}
  //       >
  //         &nbsp;
  //       </div>
  //     ),
  //   },
  //   {
  //     title: "ALEXANDRIA",
  //     color: null,
  //     content: (
  //       <div
  //         style={{
  //           width: "100%",
  //           height: bannerHeight,
  //           backgroundImage: `url("/images/pp4mnc/papa-model-02@2x.png")`,
  //           backgroundPosition: "center top",
  //           backgroundSize: "cover",
  //           maxWidth: "100%",
  //           boxShadow: "0 0 8px 8px #111111 inset",
  //           backgroundRepeat: "no-repeat",
  //         }}
  //       >
  //         &nbsp;
  //       </div>
  //     ),
  //   },
  //   {
  //     title: "GARRISON",
  //     color: null,
  //     content: (
  //       <div
  //         style={{
  //           height: bannerHeight,
  //           backgroundImage: `url("/images/pp4mnc/papa-model-03@2x.png")`,
  //           backgroundPosition: "center top",
  //           backgroundSize: "cover",
  //           maxWidth: "100%",
  //           boxShadow: "0 0 8px 8px #111111 inset",
  //           backgroundRepeat: "no-repeat",
  //           marginLeft: "auto",
  //           marginRight: "auto",
  //         }}
  //       >
  //         &nbsp;
  //       </div>
  //     ),
  //   },
  //   {
  //     title: "LOLITA",
  //     color: null,
  //     content: (
  //       <div
  //         style={{
  //           width: "100%",
  //           height: bannerHeight,
  //           backgroundImage: `url("/images/pp4mnc/papa-model-04@2x.png")`,
  //           backgroundPosition: "center top",
  //           backgroundSize: "cover",
  //           maxWidth: "100%",
  //           boxShadow: "0 0 8px 8px #111111 inset",
  //           backgroundRepeat: "no-repeat",
  //         }}
  //       >
  //         &nbsp;
  //       </div>
  //     ),
  //   },
  //   {
  //     title: "BARBIE",
  //     color: null,
  //     content: (
  //       <div
  //         style={{
  //           height: bannerHeight,
  //           backgroundImage: `url("/images/pp4mnc/papa-model-05@2x.png")`,
  //           backgroundPosition: "center top",
  //           backgroundSize: "cover",
  //           maxWidth: "100%",
  //           boxShadow: "0 0 8px 8px #111111 inset",
  //           backgroundRepeat: "no-repeat",
  //           marginLeft: "auto",
  //           marginRight: "auto",
  //         }}
  //       >
  //         &nbsp;
  //       </div>
  //     ),
  //   },
  //   {
  //     title: "RUTH",
  //     color: null,
  //     content: (
  //       <div
  //         style={{
  //           width: "100%",
  //           height: bannerHeight,
  //           backgroundImage: `url("/images/pp4mnc/papa-model-06@2x.png")`,
  //           backgroundPosition: "center top",
  //           backgroundSize: "cover",
  //           maxWidth: "100%",
  //           boxShadow: "0 0 8px 8px #111111 inset",
  //           backgroundRepeat: "no-repeat",
  //         }}
  //       >
  //         &nbsp;
  //       </div>
  //     ),
  //   },
  // ];
  // const getCarousel = () => {
  //   return (
  //     <Carousel
  //       className={classes.carousel}
  //       autoPlay={true}
  //       //timer={5000}
  //       animation="fade" //fade or slide
  //       indicators={true}
  //       timeout={1000}
  //       navButtonsAlwaysVisible={false}
  //       navButtonsAlwaysInvisible={true}
  //     >
  //       <GridList
  //         cols={2}
  //         cellHeight="auto"
  //         style={{
  //           width: "100%",
  //           flexWrap: "nowrap",
  //           transform: "translateZ(0)",
  //           overflowY: "hidden",
  //         }}
  //       >
  //         <GridListTile
  //           style={{ backgroundColor: bannerItems[0].color || "transparent" }}
  //         >
  //           {bannerItems[0].content}

  //           <GridListTileBar title={bannerItems[0].title} />
  //         </GridListTile>
  //         <GridListTile
  //           style={{ backgroundColor: bannerItems[1].color || "transparent" }}
  //         >
  //           {bannerItems[1].content}

  //           <GridListTileBar title={bannerItems[1].title} />
  //         </GridListTile>
  //       </GridList>
  //       <GridList
  //         cols={2}
  //         cellHeight="auto"
  //         style={{
  //           width: "100%",
  //           flexWrap: "nowrap",
  //           transform: "translateZ(0)",
  //           overflowY: "hidden",
  //         }}
  //       >
  //         <GridListTile
  //           style={{ backgroundColor: bannerItems[2].color || "transparent" }}
  //         >
  //           {bannerItems[2].content}

  //           <GridListTileBar title={bannerItems[2].title} />
  //         </GridListTile>
  //         <GridListTile
  //           style={{ backgroundColor: bannerItems[3].color || "transparent" }}
  //         >
  //           {bannerItems[3].content}

  //           <GridListTileBar title={bannerItems[3].title} />
  //         </GridListTile>
  //       </GridList>
  //       <GridList
  //         cols={2}
  //         cellHeight="auto"
  //         style={{
  //           width: "100%",
  //           flexWrap: "nowrap",
  //           transform: "translateZ(0)",
  //           overflowY: "hidden",
  //         }}
  //       >
  //         <GridListTile
  //           style={{ backgroundColor: bannerItems[4].color || "transparent" }}
  //         >
  //           {bannerItems[4].content}

  //           <GridListTileBar title={bannerItems[4].title} />
  //         </GridListTile>
  //         <GridListTile
  //           style={{ backgroundColor: bannerItems[5].color || "transparent" }}
  //         >
  //           {bannerItems[5].content}

  //           <GridListTileBar title={bannerItems[5].title} />
  //         </GridListTile>
  //       </GridList>
  //     </Carousel>
  //   );
  // };

  // const getOurSupportersSection = () => {
  //   return (
  //     <div style={{ marginTop: 40, padding: 20 }}>
  //       <Hidden mdUp>
  //         <Typography variant="h2" color="secondary" style={{ textAlign: "center" }}>
  //           Our Supporters
  //         </Typography>
  //       </Hidden>
  //       <Hidden smDown>
  //         <Typography variant="h1" color="secondary" style={{ textAlign: "center" }}>
  //           Our Supporters
  //         </Typography>
  //       </Hidden>

  //       <Grid container style={{ textAlign: "center" }}>
  //         <Grid item sm={6} xs={12}>
  //           <Typography variant="h6" color="secondary" style={{ textAlign: "center" }}>
  //             Penn State
  //           </Typography>
  //           <img src="images/logos/psuLogo.png" alt="Penn State University" style={{ maxHeight: 200, maxWidth: "100%" }} />
  //         </Grid>
  //         <Grid item sm={6} xs={12}>
  //           <Typography variant="h6" color="secondary" style={{ textAlign: "center" }}>
  //             PBS 39
  //           </Typography>
  //           <img src="images/logos/PBS39Logo.png" alt="PBS 39" style={{ maxHeight: 200, maxWidth: "100%" }} />
  //         </Grid>
  //       </Grid>
  //     </div>
  //   );
  // };

  const getOurCommunitySection = () => {
    return (
      <Grid
        container
        style={{ marginTop: 40, padding: 20 }}
        alignItems="center"
        justify="center"
      >
        {/* <Grid item md={6}> */}
        <div style={{ textAlign: "left" }}>
          <Hidden mdUp>
            <Typography
              variant="h2"
              color="secondary"
              style={{ textAlign: "center" }}
            >
              Our Community
            </Typography>
          </Hidden>
          <Hidden smDown>
            <Typography
              variant="h2"
              color="secondary"
              style={{ textAlign: "center" }}
            >
              Our Community
            </Typography>
          </Hidden>
          <Typography
            variant="body1"
            color="secondary"
            style={{ textAlign: "center", maxWidth: 900 }}
          >
            Our Community is powerful because of its many members. Together, we
            are here to support one another physically, emotionally, mentally,
            and spiritually. No one is alone as we celebrate achievements,
            milestones, and reach for higher goals.
          </Typography>
        </div>
        {/* </Grid>
        <Grid item md={6} style={{ width: "100%" }}>
          <div
            style={{
              width: "100%",
              height: 600,
              minWidth: "100%",
              backgroundImage: `url("/images/banners/banner1.jpg")`,
              //backgroundPosition: "center calc(40%)",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              borderRadius: 8,
            }}
          >
            &nbsp;
          </div> */}

        <img
          src={communityImg.current}
          alt="ParaPer4mance Community"
          style={{
            display: "block",
            maxWidth: "100%",
            maxHeight: 700,
            width: "auto",
            height: "auto",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 8,
          }}
        />
      </Grid>
    );
  };

  // const getOurStoriesSection = () => {
  //   return (
  //     <div style={{ marginTop: 40, padding: 20 }}>
  //       <Typography variant="h2" color="secondary" style={{ textAlign: "center" }}>
  //         Our Stories
  //       </Typography>
  //       <Typography variant="body1" color="secondary" style={{ textAlign: "center" }}>
  //         Everyone has a story of impact - who they are, where they have been, and most importantly, where they want to go. No matter how your story began, YOU are in control of
  //         where to go next with ParaPer4mance GO!
  //       </Typography>

  //       <GridList cols={4} cellHeight="auto" style={{ width: "100%", flexWrap: "nowrap", transform: "translateZ(0)", overflowY: "hidden" }}>
  //         <GridListTile>
  //           <div
  //             style={{
  //               height: 200,
  //               backgroundImage: `url("/images/pp4mnc/np_backbone.svg")`,
  //               backgroundPosition: "center",
  //               backgroundSize: "80%",
  //               maxWidth: "100%",
  //               //boxShadow: "0 0 8px 8px #111111 inset",
  //               backgroundRepeat: "no-repeat",
  //               marginLeft: "auto",
  //               marginRight: "auto",
  //             }}
  //           >
  //             &nbsp;
  //           </div>

  //           <GridListTileBar title="SPINAL CORD INJURY" />
  //         </GridListTile>
  //         <GridListTile>
  //           <div
  //             style={{
  //               height: 200,
  //               backgroundImage: `url("/images/pp4mnc/np_back.svg")`,
  //               backgroundPosition: "center",
  //               backgroundSize: "80%",
  //               maxWidth: "100%",
  //               //boxShadow: "0 0 8px 8px #111111 inset",
  //               backgroundRepeat: "no-repeat",
  //               marginLeft: "auto",
  //               marginRight: "auto",
  //             }}
  //           >
  //             &nbsp;
  //           </div>

  //           <GridListTileBar title="DEGENERATIVE CONDITION" />
  //         </GridListTile>
  //         <GridListTile>
  //           <div
  //             style={{
  //               height: 200,
  //               backgroundImage: `url("/images/pp4mnc/np_wheel-chair.svg")`,
  //               backgroundPosition: "center",
  //               backgroundSize: "80%",
  //               maxWidth: "100%",
  //               //boxShadow: "0 0 8px 8px #111111 inset",
  //               backgroundRepeat: "no-repeat",
  //               marginLeft: "auto",
  //               marginRight: "auto",
  //             }}
  //           >
  //             &nbsp;
  //           </div>

  //           <GridListTileBar title="PARALYSIS" />
  //         </GridListTile>
  //         <GridListTile>
  //           <div
  //             style={{
  //               height: 200,
  //               backgroundImage: `url("/images/pp4mnc/np_foot-injury.svg")`,
  //               backgroundPosition: "center",
  //               backgroundSize: "80%",
  //               maxWidth: "100%",
  //               //boxShadow: "0 0 8px 8px #111111 inset",
  //               backgroundRepeat: "no-repeat",
  //               marginLeft: "auto",
  //               marginRight: "auto",
  //             }}
  //           >
  //             &nbsp;
  //           </div>

  //           <GridListTileBar title="CAREGIVER" />
  //         </GridListTile>
  //       </GridList>
  //     </div>
  //   );
  // };

  return (
    <div className={classes.mainContentDiv}>
      <Snackbar open={openAppAlert} autoHideDuration={6000} onClose={()=>{setOpenAppAlert(false)}} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
  <Alert onClose={()=>{setOpenAppAlert(false)}} severity="success">
    We are currently reworking the app to bring you a better user experience. Follow us on social media for updates!
  </Alert>
</Snackbar>
      <Pp4mncAppBar openState={open} setOpenState={setOpen} />
      <Pp4mncDrawer
        currentPage={Pages.HOME}
        openState={open}
        setOpenState={setOpen}
      />
      {getIntroSection()}
      {getAppSection()}
      {getAdditionalInfoSection()}
      {getOurCommunitySection()}
      {/* {getOurSupportersSection()} */}
      {/* {getOurStoriesSection()} */}
      <Pp4mncFooter />
    </div>
  );
};

export default Home;
